import React, { useState, useEffect, useRef, ReactNode } from "react";
import type { Dayjs } from "dayjs";
import type { CalendarProps } from "antd";
import moment from "moment";
import Emitter from "../utils/emitter";
import {
    Button,
    Calendar,
    Row,
    Col,
    Table,
    Modal,
    List,
    Card,
    Space,
    ConfigProvider,
    Divider,
    theme,
    Badge,
} from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import {
    CloseOutlined,
    ExclamationCircleOutlined,
    RightOutlined,
    UserOutlined,
    SolutionOutlined,
} from "@ant-design/icons";
import { getLookupItem } from "../utils/lookup_list";
import { capitalizeFirstLetter, daysCalculation } from "../utils/utils";
import birthdayCakeSvg from "../assets/image/birthday cake.svg";
import { socket } from "../utils/socket";
import { isMobile } from "react-device-detect";

// Upcoming Public Holidays List
interface PublicHoliday {
    key: string;
    holiday_name: string;
    ph_date: string;
    day: string;
    countdown_days: string;
}

// Dictionary for weekdays
// Define a type for weekdays object
interface Offday {
    weekday: keyof Weekdays; // This indicates that the property "weekday" must be one of the keys of the Weekdays type
}

// Assuming offdays is an array of Offday objects
const offdays: Offday[] = [
    { weekday: "Sunday" },
    { weekday: "Saturday" },
    // Add more Offday objects as needed
];
type Weekdays = {
    Sunday: number;
    Monday: number;
    Tuesday: number;
    Wednesday: number;
    Thursday: number;
    Friday: number;
    Saturday: number;
};

// Define a type for offday object
interface Offday {
    weekday: keyof Weekdays; // This indicates that the property "weekday" must be one of the keys of the Weekdays type
}

const weekdays: Weekdays = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
};

// Pagination Options for the Upcoming Holiday List
const paginationOptions: TablePaginationConfig = {
    pageSize: 5, // Adjust this number to change the number of rows per page
    size: "small", // Set pagination size to small
};

const CustomCloseIcon = () => (
    <CloseOutlined style={{ fontSize: "20px", color: "white" }} />
);

const LeaveOverview = (props: any) => {
    const role = props?.role;
    const userData = props?.userData;
    const setTabKey = props?.setTabKey;
    const setSubTabKey = props?.setSubTabKey;
    const setSideContainerOpen = props?.setSideContainerOpen;
    const setActiveRowKey = props?.setActiveRowKey;
    const setActiveRecord = props?.setActiveRecord;
    const holidayData = props?.holidayData;
    const workdayData = props?.workdayData;

    // Hook for position_obj
    const [positionObj, setPositionObj] = useState<any>(null);
    // Hook for off days
    const [offdays, setOffdays] = useState<string[]>([]);

    // Hooks for loading indicators
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ready, setReady] = useState<any>(false);

    // Hooks for modal pop-up
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);

    // Hooks for Calendar Component
    const [mode, setMode] = useState("month");

    // Hooks for leave application data and statistic
    const [leavedata, setLeaveData] = useState([]);
    const [specificdateleavedata, setSpecificDateLeaveData] = useState([]);
    const [specificmonthleavedata, setSpecificMonthLeaveData] = useState([]);
    const [pendingStatistic, setPendingStatistic] = useState<any>(null);
    const [approvedStatistic, setApprovedStatistic] = useState<any>(null);
    const [rejectedStatistic, setRejectedStatistic] = useState<any>(null);
    const [pendingSpecificDateStatistic, setPendingSpecificDateStatistic] =
        useState<any>(null);
    const [approvedSpecificDateStatistic, setApprovedSpecificDateStatistic] =
        useState<any>(null);
    const [pendingSpecificMonthStatistic, setPendingSpecificMonthStatistic] =
        useState<any>(null);
    const [approvedSpecificMonthStatistic, setApprovedSpecificMonthStatistic] =
        useState<any>(null);
    // Hook for birthday data
    const [birthdays, setBirthdays] = useState<any>([]);

    // Hook for upcoming public holidays data
    const phRef = useRef<PublicHoliday[]>([]);

    // Hook for page change
    const [pageChange, setPageChange] = useState(false);

    const { token } = theme.useToken();

    const wrapperStyle: React.CSSProperties = {
        width: 300,
        border: `1px solid ${token.colorBorderSecondary}`,
        borderRadius: token.borderRadiusLG,
    };

    // Calendar Modal Component Configurations
    const handleDateClick = (value: any) => {
        //console.log("Page", pageChange);
        if (pageChange === false) {
            setSelectedDate(value);
            const timestamp = value.$d;
            const date = new Date(timestamp);
            const dateStr = value.format("YYYY-MM-DD");

            const day = date.getDate();
            const month = date.toLocaleString("default", { month: "short" });
            const year = date.getFullYear();

            const formattedDate: any = `${day} ${month} ${year}`;
            setSelectedDate(formattedDate);
            setModalVisible(true);

            // Count the number of pending and approved statuses for a specifit date
            let specific_date_leave_data: any = [];
            let pendingCount = 0;
            let approvedCount = 0;

            leavedata?.forEach((record: any) => {
                const startDate = record.start_date.split(" ")[0];
                const endDate = record.end_date.split(" ")[0];
                const status = record.status;

                if (dateStr >= startDate && dateStr <= endDate) {
                    if (status === "approved") {
                        specific_date_leave_data.push(record);
                        approvedCount++;
                    } else if (status === "pending") {
                        pendingCount++;
                        specific_date_leave_data.push(record);
                    }
                }
            });

            setSpecificDateLeaveData(specific_date_leave_data);
            setPendingSpecificDateStatistic(pendingCount);
            setApprovedSpecificDateStatistic(approvedCount);
            specific_date_leave_data = [];

            // Count the number of pending and approved statuses for a specific month
            let specific_month_leave_data: any = [];
            let pendingMonthCount = 0;
            let approvedMonthCount = 0;

            leavedata?.forEach((record: any) => {
                const monthStart = value
                    .clone()
                    .startOf("month")
                    .format("YYYY-MM-DD");
                const monthEnd = value
                    .clone()
                    .endOf("month")
                    .format("YYYY-MM-DD");
                const startDate = record.start_date.split(" ")[0];
                const endDate = record.end_date.split(" ")[0];
                const status = record.status;

                if (
                    (startDate >= monthStart && startDate <= monthEnd) ||
                    (endDate >= monthStart && endDate <= monthEnd)
                ) {
                    if (status === "approved") {
                        specific_month_leave_data.push(record);
                        approvedMonthCount++;
                    } else if (status === "pending") {
                        pendingMonthCount++;
                        specific_month_leave_data.push(record);
                    }
                }
            });

            // Filtering specific_month_leave_data based on startDate and endDate
            specific_month_leave_data.sort((a: any, b: any) => {
                const startDateA = a.start_date.split(" ")[0];
                const startDateB = b.start_date.split(" ")[0];
                if (startDateA === startDateB) {
                    const endDateA = a.end_date.split(" ")[0];
                    const endDateB = b.end_date.split(" ")[0];
                    return (
                        new Date(endDateA).getTime() -
                        new Date(endDateB).getTime()
                    );
                }
                return (
                    new Date(startDateA).getTime() -
                    new Date(startDateB).getTime()
                );
            });

            setSpecificMonthLeaveData(specific_month_leave_data);
            setPendingSpecificMonthStatistic(pendingMonthCount);
            setApprovedSpecificMonthStatistic(approvedMonthCount);
            specific_month_leave_data = [];
        } else {
            setPageChange(false);
        }
    };

    const handleModalClose = () => {
        setModalVisible(false);
    };

    // Columns Configuration for Upcoming Public Holidays List
    const columns: ColumnsType<PublicHoliday> = [
        {
            title: <div className="overview-ph-table-header">Holiday Name</div>,
            dataIndex: "holiday_name",
            align: "left",
            width: 200,
            key: "holiday_name",
        },
        {
            title: <div className="overview-ph-table-header">Date</div>,
            dataIndex: "ph_date",
            key: "ph_date",
            align: "center",
            width: 150,
            render: (text: string) => moment(text).format("DD MMM YYYY"),
        },
        {
            title: <div className="overview-ph-table-header">Day</div>,
            dataIndex: "day",
            key: "day",
            align: "center",
            width: 150,
            render: (text: any, record: any) => {
                const date = moment(record.ph_date, "YYYY-MM-DD");
                return date.format("dddd");
            },
        },
        {
            title: (
                <div className="overview-ph-table-header">Countdown Day(s)</div>
            ),
            dataIndex: "countdown_days",
            key: "countdown_days",
            align: "center",
            width: 150,
            render: (text: any, record: any) => {
                const date = moment(record.ph_date, "YYYY-MM-DD");
                const today = moment().startOf("day");
                const diffDays = date.diff(today, "days");
                let countdownText = "";
                if (diffDays === 0) {
                    countdownText = "Today";
                } else if (diffDays === 1) {
                    countdownText = "1 more day";
                } else if (diffDays > 1) {
                    countdownText = `${diffDays} more days`;
                }
                return (
                    <span style={{ fontStyle: "italic" }}>{countdownText}</span>
                );
            },
        },
    ];

    const fetchData = () => {
        // Fetch data from the server
        getLookupItem("holiday")
            .then((data: any) => {
                //console.log("Upcoming Public Holidays:", data);
                phRef.current = data;
            })
            .catch((error: any) => {
                console.error("Error fetching public holidays data: ", error);
            });

        // Fetch data from the server
        getLookupItem("leave")
            .then((data: any) => {
                //console.log("Test Leave Applications:", data);
                setLeaveData(data);
            })
            .catch((error: any) => {
                console.error("Error fetching leave application data: ", error);
            });

        getLookupItem("position").then((data: any) => {
            let positionTemp: any = {};
            data.forEach((position: any) => {
                positionTemp[position.position_code] = position.position_name;
            });
            setPositionObj(positionTemp);
        });

        getLookupItem("workday").then((workday_data: any) => {
            const filteredOffDays = workday_data.filter(
                (item: any) => item.workperiod === "offday"
            );

            setOffdays(filteredOffDays);
        });
    };

    useEffect(() => {
        setActiveRecord(null);
        setSideContainerOpen(false);
        fetchData();

        socket.on("refresh-leave-data", () => {
            fetchData();
        });
    }, []);

    useEffect(() => {
        // Name formatting
        const formatname = (name: any) => {
            // Split the string into words
            const words = name.split(" ");

            // Capitalize the first letter of each word
            const capitalizedWords = words.map((word: any) => {
                return (
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                );
            });

            // Join the capitalized words back into a single string
            return capitalizedWords.join(" ");
        };

        // Fetch employee data using getLookupItem function
        getLookupItem("employee")
            .then((employeeList: any) => {
                // Find the employee by staffId
                let birthdaylist: any = [];
                employeeList
                    .filter((item: any) => item.status === "active")
                    .forEach((employee: any) => {
                        const birthday = employee.nric.slice(2, 6);
                        const month = parseInt(birthday.substring(0, 2)) - 1; // Month is zero-indexed
                        const day = parseInt(birthday.substring(2));
                        const birthdate = moment(
                            new Date(currentYear, month, day)
                        );
                        const employee_bday = {
                            fullname_nric: formatname(employee.fullname_nric),
                            birthday: birthdate.format("MM-DD"),
                        };
                        birthdaylist.push(employee_bday); // Push the employee's birthday object to the list
                    });
                // console.log("Birthday list 1", birthdaylist);
                setBirthdays(birthdaylist);
            })
            .catch((error: any) => {
                console.error("Error fetching employee data: ", error);
            });
        // console.log("Birthday list 2", birthdays);
    }, []);

    //Function to calculate leave balance
    const calculateLeaveBalance = (entitled: number, used: number) =>
        entitled - used;

    let currentMonthPending = 0;
    let currentMonthApproved = 0;
    let currentMonthRejected = 0;
    let currentYearPending = 0;
    let currentYearApproved = 0;
    let currentYearRejected = 0;

    // Get current month and year
    const currentDate: any = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    leavedata?.forEach((entry: any) => {
        const entryDate = new Date(entry.start_date);
        const entryMonth = entryDate.getMonth() + 1;
        const entryYear = entryDate.getFullYear();

        // Check if entry is in the current month
        if (entryMonth === currentMonth && entryYear === currentYear) {
            // Increment counters based on status
            switch (entry.status) {
                case "pending":
                    currentMonthPending++;
                    break;
                case "approved":
                    currentMonthApproved++;
                    break;
                case "rejected":
                    currentMonthRejected++;
                    break;
                default:
                    break;
            }
        }

        // Increment year counters based on status
        switch (entry.status) {
            case "pending":
                currentYearPending++;
                break;
            case "approved":
                currentYearApproved++;
                break;
            case "rejected":
                currentYearRejected++;
                break;
            default:
                break;
        }
    });

    useEffect(() => {
        //update current leave application statistic for the current month
        setPendingStatistic(currentMonthPending);
        setApprovedStatistic(currentMonthApproved);
        setRejectedStatistic(currentMonthRejected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMonthPending, currentMonthApproved, currentMonthRejected]);

    const onPanelChange = (
        value: Dayjs,
        mode: CalendarProps<Dayjs>["mode"]
    ) => {
        if (pageChange === false) {
            setPageChange(true);
        }

        const targetMonth = value.month() + 1; // Adding 1 to match the data where January is 1
        const targetYear = value.year();

        // Re-initialize counters for pending, approved, and rejected statuses
        currentMonthPending = 0;
        currentMonthApproved = 0;
        currentMonthRejected = 0;
        currentYearPending = 0;
        currentYearApproved = 0;
        currentYearRejected = 0;

        // Iterate over the leave data
        leavedata?.forEach((leave: any) => {
            // Extract the start date of the leave
            const startDate = new Date(leave.start_date);
            const startMonth = startDate.getMonth() + 1; // Adding 1 to match JavaScript month index

            // Check if the leave falls within the specified month and year
            if (startDate.getFullYear() === targetYear) {
                // Increment the corresponding counter based on the status
                const status = leave.status;
                if (status === "pending") {
                    currentYearPending++;
                    if (startMonth === targetMonth) {
                        currentMonthPending++;
                    }
                } else if (status === "approved") {
                    currentYearApproved++;
                    if (startMonth === targetMonth) {
                        currentMonthApproved++;
                    }
                } else if (status === "rejected") {
                    currentYearRejected++;
                    if (startMonth === targetMonth) {
                        currentMonthRejected++;
                    }
                }
            }
        });

        if (mode === "month") {
            setMode("month");
            setSelectedDate(null); // Clear selected date
            setPendingStatistic(currentMonthPending);
            setApprovedStatistic(currentMonthApproved);
            setRejectedStatistic(currentMonthRejected);
        } else if (mode === "year") {
            setMode("year");
            setSelectedDate(null); // Clear selected date
            setPendingStatistic(currentYearPending);
            setApprovedStatistic(currentYearApproved);
            setRejectedStatistic(currentYearRejected);
        } else {
            setSelectedDate(null); // Clear selected date
        }
    };

    // Date Rendering Function
    const dateCellRender = (date: any): React.ReactNode => {
        const dateStr = date.format("YYYY-MM-DD");
        let approvedCount = 0;
        let pendingCount = 0;
        let holidayNames: any = [];
        let birthdayList: any = [];
        let offday_name = "";

        // Check for birthdays
        birthdayList = birthdays.filter(
            (employee: any) => employee.birthday === dateStr.slice(5)
        );

        // Check for offdays
        const offdays_no: number[] = offdays.map((offday: any) => {
            const offdayTyped = offday as Offday;
            return weekdays[offdayTyped.weekday];
        });
        if (offdays_no.includes(new Date(date).getDay())) {
            offday_name = "Offday";
        }

        // Check for holidays
        const holiday = publicHolidayData?.filter(
            (holiday: any) => holiday.ph_date.substring(0, 10) === dateStr
        );
        if (holiday) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            holidayNames = holiday;
        } else {
            holidayNames = [];
        }

        leavedata?.forEach((record: any) => {
            const startDate = record.start_date.split(" ")[0];
            const endDate = record.end_date.split(" ")[0];
            const status = record.status;

            if (holidayNames.length === 0 && offday_name !== "Offday") {
                if (dateStr >= startDate && dateStr <= endDate) {
                    if (status === "approved") {
                        approvedCount++;
                    } else if (status === "pending") {
                        pendingCount++;
                    }
                }
            }
        });

        return (
            <div>
                <div style={{ textAlign: "center" }}>
                    {birthdayList.length > 0 &&
                        !isMobile &&
                        birthdayList.map((employee: any, index: number) => (
                            <div key={index}>
                                <span>
                                    {employee.fullname_nric}'s Birthday <br />
                                    <svg
                                        width="50"
                                        height="50"
                                        viewBox="0 0 50 50"
                                    >
                                        <image
                                            xlinkHref={birthdayCakeSvg}
                                            width="50"
                                            height="50"
                                        />
                                    </svg>
                                    {/* <br /> */}
                                </span>
                                <br />
                            </div>
                        ))}
                    {holidayNames.length > 0 || offday_name === "Offday"
                        ? !isMobile &&
                          holidayNames.map((holiday: any, index: number) => (
                              <div key={index}>
                                  <span>
                                      {holiday.holiday_name} <br />
                                  </span>
                                  <br />
                              </div>
                          ))
                        : (isMobile && <></>) || (
                              <div>
                                  {approvedCount > 0 && (
                                      <div>
                                          <Button className="overview-approved-month-label">
                                              {approvedCount} <UserOutlined />
                                          </Button>
                                      </div>
                                  )}
                                  {pendingCount > 0 && (
                                      <div>
                                          <Button className="overview-pending-month-label">
                                              {pendingCount} <UserOutlined />
                                          </Button>
                                      </div>
                                  )}
                              </div>
                          )}
                </div>
            </div>
        );
    };

    // Month Rendering Function
    const monthCellRender = (value: Dayjs): React.ReactNode => {
        const monthStart = value.clone().startOf("month").format("YYYY-MM-DD");
        const monthEnd = value.clone().endOf("month").format("YYYY-MM-DD");
        let approvedCount = 0;
        let pendingCount = 0;

        leavedata?.forEach((record: any) => {
            const startDate = record.start_date.split(" ")[0];
            const endDate = record.end_date.split(" ")[0];
            const status = record.status;

            // Check if any part of the leave period falls within the current month
            if (
                (startDate >= monthStart && startDate <= monthEnd) ||
                (endDate >= monthStart && endDate <= monthEnd)
            ) {
                if (status === "approved") {
                    approvedCount++;
                } else if (status === "pending") {
                    pendingCount++;
                }
            }
        });

        return (
            (isMobile && <></>) || (
                <div style={{ textAlign: "center" }}>
                    {approvedCount > 0 && (
                        <div>
                            <Button className="overview-approved-year-label">
                                {approvedCount} <SolutionOutlined />
                            </Button>
                        </div>
                    )}
                    {pendingCount > 0 && (
                        <div>
                            <Button className="overview-pending-year-label">
                                {pendingCount} <SolutionOutlined />
                            </Button>
                        </div>
                    )}
                </div>
            )
        );
    };

    let publicHolidayData: any = [];
    // You can access phRef.current here as well
    if (phRef.current) {
        // Null check before iterating through the array
        phRef.current.forEach((item: any) => {
            //console.log(item);
            publicHolidayData.push(item);
        });
    } else {
        //console.log("phRef.current is undefined or null.");
    }

    // Filter out rows with dates that have already passed
    const filteredPHdata = publicHolidayData.filter((item: any) => {
        const date = moment(item.ph_date, "YYYY-MM-DD");
        const currentDate = moment().startOf("day");
        return date.isSameOrAfter(currentDate, "day");
    });

    // Sort the filtered data by the date column in ascending order
    filteredPHdata.sort((a: any, b: any) => {
        const dateA: any = moment(a.ph_date, "YYYY-MM-DD");
        const dateB: any = moment(b.ph_date, "YYYY-MM-DD");
        return dateA - dateB;
    });

    // Function to highlight the closest date to the current date in the 'Upcoming  Public Holidays' Table
    const highlightClosestDate = (dates: string[]) => {
        if (dates.length === 0) return ""; // Return empty string if no dates are provided

        const firstDate = new Date(dates[0]); // Convert the first date to a Date object
        return (record: any) => {
            const currentDate = new Date(record.ph_date); // Convert the current date to a Date object
            const timeDiff = Math.abs(
                currentDate.getTime() - firstDate.getTime()
            ); // Calculate the time difference in milliseconds
            return timeDiff === 0 ? "closest-date" : "normal-date"; // Return 'closest-date' if it's the closest, otherwise return ''
        };
    };

    useEffect(() => {
        setReady(false);
        Emitter.emit("loading", null);

        Promise.all([getLookupItem("holiday"), getLookupItem("leave")])
            .then(([holidayData, leaveData]: [any, any]) => {
                // Handle holiday data
                // console.log("Upcoming Public Holidays:", holidayData);
                phRef.current = holidayData;

                // Handle leave data
                // console.log("Leave Applications:", leaveData);
                setLeaveData(leaveData);

                // Set ready state and emit finish-loading event
                setReady(true);
                Emitter.emit("finish-loading", null);
            })
            .catch((error: any) => {
                console.error("Error fetching data: ", error);
                // Ensure ready state is set to true even in case of errors
                setReady(true);
                Emitter.emit("finish-loading", null);
            });
    }, []);

    const modalRendering = (item: any) => {
        return (
            <div
                style={{
                    overflowX: "auto",
                    display: isMobile ? "inline-block" : "flex",
                    // display:"inline-block",
                    whiteSpace: "normal",
                    justifyContent: "flex-end",
                    width: "95%",
                }}
                onClick={() => {
                    if (role === "admin") {
                        setTabKey("leaveManager");
                        setSubTabKey(`${item.status}`);
                        setSideContainerOpen(true);
                        setActiveRowKey(`${item.leave_id_num}`);
                        setActiveRecord(item);
                    }

                    // setHolidayData(holidayData);
                    // setWorkdayData(workdayData);
                }}
            >
                <div
                    style={{
                        flex: 1,
                        minWidth: "200px",
                    }}
                >
                    <p>{`${item.fullname_nric}`}</p>
                    <p>{`#${item.staff_id_num}`}</p>
                    <p>{`${positionObj[item.position]}`}</p>
                </div>
                <div
                    style={{
                        flex: 1,
                        minWidth: "200px",
                    }}
                >
                    <p
                        style={{
                            fontWeight: "bold",
                        }}
                    >
                        {`${item.leave_type}`.toUpperCase()}
                    </p>
                    {/* <p>{`${moment(item.start_date).format('DD MMM YYYY')} - ${moment(item.end_date).format('DD MMM YYYY')}`}</p> */}
                    {/* <p>{`${moment(item.start_date).isSame(item.end_date, 'day') ? moment(item.start_date).format('DD MMM YYYY') : `${moment(item.start_date).format('DD MMM YYYY')} - ${moment(item.end_date).format('DD MMM YYYY')}`}`}</p> */}
                    <p>
                        {moment(item.start_date).format("DD MMM YYYY")}
                        {moment(item.start_date).isSame(item.end_date, "day")
                            ? ""
                            : ` - ${moment(item.end_date).format(
                                  "DD MMM YYYY"
                              )}`}
                    </p>
                    {daysCalculation(
                        workdayData,
                        holidayData,
                        item.start_date,
                        item.end_date,
                        item.half_days
                    ) <= 1 && (
                        <p
                            style={{
                                fontStyle: "italic",
                            }}
                        >
                            {" "}
                            {daysCalculation(
                                workdayData,
                                holidayData,
                                item.start_date,
                                item.end_date,
                                item.half_days
                            )}{" "}
                            day
                        </p>
                    )}
                    {daysCalculation(
                        workdayData,
                        holidayData,
                        item.start_date,
                        item.end_date,
                        item.half_days
                    ) > 1 && (
                        <p
                            style={{
                                fontStyle: "italic",
                            }}
                        >
                            {" "}
                            {daysCalculation(
                                workdayData,
                                holidayData,
                                item.start_date,
                                item.end_date,
                                item.half_days
                            )}{" "}
                            days
                        </p>
                    )}
                    {isMobile && (
                        <Badge
                            count={capitalizeFirstLetter(item.status)}
                            color={
                                item.status === "pending" ? "orange" : "green"
                            }
                        ></Badge>
                    )}
                </div>

                {!isMobile && (
                    <>
                        <div
                            className={`status-label ${
                                item.status === "pending"
                                    ? "pending"
                                    : item.status === "approved"
                                    ? "approved"
                                    : ""
                            }`}
                        >
                            <p>{capitalizeFirstLetter(item.status)}</p>
                        </div>
                        {role === "admin" && (
                            <div
                                style={{
                                    flex: 0,
                                    justifyContent: "left",
                                    marginTop: "50px",
                                    marginLeft: "150px",
                                }}
                            >
                                <RightOutlined
                                    style={{
                                        fontSize: "24px",
                                    }}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    };

    return (
        <>
            <Row justify="center" style={{ height: "100", overflow: "auto" }}>
                {/* <Col span={16}> */}
                <Col lg={{ span: 24 }} xl={{ span: 16 }}>
                    {role === "admin" && (
                        <Row justify="center" style={{width:"100%"}}>
                            <Space direction={isMobile? "vertical" : "horizontal"} size="small">
                                <Col style={{display:"flex", flexDirection:"column", paddingLeft:"15%", width:"100%"}}>
                                    <Button
                                        type="primary"
                                        className="pending-status-button"
                                        onClick={() => {
                                            setTabKey("leaveManager");
                                            setSubTabKey("pending");
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: "#ff9901",
                                                padding: "5px",
                                                position: "absolute",
                                                right: 0,
                                                top: 0,
                                            }}
                                        >
                                            <ExclamationCircleOutlined
                                                style={{ fontSize: "25px" }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                color: "grey",
                                                padding: "5px",
                                            }}
                                        >
                                            Pending
                                        </div>
                                        <div
                                            style={{
                                                textAlign: "center",
                                                fontSize: "50px",
                                                fontWeight: "bold",
                                                color: "#ff9901",
                                                padding: "5px",
                                            }}
                                        >
                                            {pendingStatistic}
                                        </div>
                                    </Button>
                                </Col>
                                <Col style={{display:"flex", flexDirection:"column", paddingLeft:"15%", width:"100%"}}>
                                    <Button
                                        type="primary"
                                        className="approved-status-button"
                                        onClick={() => {
                                            setTabKey("leaveManager");
                                            setSubTabKey("approved");
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: "grey",
                                                padding: "5px",
                                            }}
                                        >
                                            Approved
                                        </div>
                                        <div
                                            style={{
                                                textAlign: "center",
                                                fontSize: "50px",
                                                fontWeight: "bold",
                                                color: "#52c41a",
                                                padding: "5px",
                                            }}
                                        >
                                            {approvedStatistic}
                                        </div>
                                    </Button>
                                </Col>
                                <Col style={{display:"flex", flexDirection:"column", paddingLeft:"15%", width:"100%"}}>
                                    <Button
                                        type="primary"
                                        className="rejected-status-button"
                                        onClick={() => {
                                            setTabKey("leaveManager");
                                            setSubTabKey("rejected");
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: "grey",
                                                padding: "5px",
                                            }}
                                        >
                                            Rejected
                                        </div>
                                        <div
                                            style={{
                                                textAlign: "center",
                                                fontSize: "50px",
                                                fontWeight: "bold",
                                                color: "#dc2d27",
                                                padding: "5px",
                                            }}
                                        >
                                            {rejectedStatistic}
                                        </div>
                                    </Button>
                                </Col>
                            </Space>
                        </Row>
                    )}
                    {role !== "admin" && (
                        <Row justify="center">
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Card: {
                                            headerFontSizeSM: 18,
                                            headerBg: "#434343",
                                            colorTextHeading: "#ffffff",
                                            borderRadiusLG: 10,
                                            colorBorderSecondary: "#434343",
                                        },
                                    },
                                }}
                            >
                                <div
                                    style={{
                                        overflowX: "auto",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <Space
                                        size={16}
                                        direction={
                                            isMobile ? "vertical" : "horizontal"
                                        }
                                    >
                                        {userData &&
                                            Object.entries(
                                                userData.leave_entitled
                                            ).map(
                                                ([leave_index, leaveDetails]: [
                                                    string,
                                                    any
                                                ]) => (
                                                    <Col>
                                                        <Card
                                                            key={
                                                                leaveDetails.leave_type
                                                            }
                                                            title={
                                                                capitalizeFirstLetter(
                                                                    leaveDetails.leave_type
                                                                ) + " Leave"
                                                            }
                                                            size="small"
                                                            style={{
                                                                width: 300,
                                                            }}
                                                        >
                                                            <h3>
                                                                Leave Balance:{" "}
                                                                {calculateLeaveBalance(
                                                                    leaveDetails.entitled,
                                                                    leaveDetails.used
                                                                )}
                                                            </h3>
                                                            <p>
                                                                Leave Taken:{" "}
                                                                {
                                                                    leaveDetails.used
                                                                }
                                                            </p>
                                                            <p>
                                                                Total Leave
                                                                Entitled:{" "}
                                                                {
                                                                    leaveDetails.entitled
                                                                }
                                                            </p>
                                                        </Card>
                                                    </Col>
                                                )
                                            )}
                                    </Space>
                                </div>
                            </ConfigProvider>
                        </Row>
                    )}
                    <Row>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "stretch",
                            }}
                        >
                            <div style={{ flex: "1" }}>
                                {/* {isMobile ? ( */}
                                <div style={isMobile ? wrapperStyle : {}}>
                                    <Calendar
                                        fullscreen={isMobile ? false : true}
                                        className="calendar-disabled mobile-calendar"
                                        onPanelChange={onPanelChange}
                                        onSelect={(date) => {
                                            handleDateClick(date);
                                        }}
                                        disabledDate={(date: any) => {
                                            const dateStr =
                                                date.format("YYYY-MM-DD");
                                            // Check if the date is a weekend (Saturday or Sunday)
                                            const offdays_no: number[] =
                                                offdays.map((offday: any) => {
                                                    const offdayTyped =
                                                        offday as Offday;
                                                    return weekdays[
                                                        offdayTyped.weekday
                                                    ];
                                                });
                                            if (mode === "month") {
                                                if (
                                                    offdays_no.includes(
                                                        new Date(date).getDay()
                                                    )
                                                ) {
                                                    return true;
                                                }
                                            } else {
                                                return false;
                                            }

                                            // Check if the date is in filteredPHdata
                                            const holiday =
                                                publicHolidayData.find(
                                                    (holiday: any) =>
                                                        holiday.ph_date.substring(
                                                            0,
                                                            10
                                                        ) === dateStr
                                                );
                                            if (mode === "month") {
                                                return holiday !== undefined; // Disable the date if it's found in filteredPHdata
                                            } else {
                                                return false;
                                            }
                                        }}
                                        style={{
                                            padding: "50px",
                                            maxWidth: "100%",
                                        }}
                                        dateCellRender={dateCellRender}
                                        monthCellRender={monthCellRender}
                                        rootClassName="calendar-root"
                                    />
                                </div>
                                {/* // ) : ( */}
                                {/*                                 
                                    <Calendar
                                        fullscreen={isMobile ? false : true}
                                        className="calendar-disabled"
                                        onPanelChange={onPanelChange}
                                        onSelect={(date) => {
                                            handleDateClick(date);
                                        }}
                                        disabledDate={(date: any) => {
                                            const dateStr =
                                                date.format("YYYY-MM-DD");
                                            // Check if the date is a weekend (Saturday or Sunday)
                                            const offdays_no: number[] =
                                                offdays.map((offday: any) => {
                                                    const offdayTyped =
                                                        offday as Offday;
                                                    return weekdays[
                                                        offdayTyped.weekday
                                                    ];
                                                });
                                            if (mode === "month") {
                                                if (
                                                    offdays_no.includes(
                                                        new Date(date).getDay()
                                                    )
                                                ) {
                                                    return true;
                                                }
                                            } else {
                                                return false;
                                            }

                                            // Check if the date is in filteredPHdata
                                            const holiday = filteredPHdata.find(
                                                (holiday: any) =>
                                                    holiday.ph_date.substring(
                                                        0,
                                                        10
                                                    ) === dateStr
                                            );
                                            if (mode === "month") {
                                                return holiday !== undefined; // Disable the date if it's found in filteredPHdata
                                            } else {
                                                return false;
                                            }
                                        }}
                                        style={{
                                            padding: "50px",
                                            maxWidth: "100%",
                                        }}
                                        dateCellRender={dateCellRender}
                                        monthCellRender={monthCellRender}
                                        rootClassName="calendar-root"
                                    />
                                )} */}

                                {(approvedSpecificDateStatistic > 0 ||
                                    pendingSpecificDateStatistic > 0) &&
                                    mode === "month" && (
                                        <Modal
                                            title={
                                                <div
                                                    style={{
                                                        padding: "15px",
                                                        backgroundColor:
                                                            "#343642",
                                                        color: "white",
                                                        minHeight: "25px",
                                                        maxHeight: "25px",
                                                    }}
                                                >
                                                    {selectedDate &&
                                                        moment(
                                                            selectedDate
                                                        ).format(
                                                            "D MMMM YYYY (dddd)"
                                                        )}
                                                </div>
                                            }
                                            visible={modalVisible}
                                            onCancel={handleModalClose}
                                            footer={null}
                                            centered
                                            width={1000}
                                            style={{ height: "550px" }}
                                            closeIcon={<CustomCloseIcon />}
                                            bodyStyle={{
                                                minHeight: 500,
                                                maxHeight: 500,
                                                overflow: "scroll",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginLeft: "10px",
                                                    marginTop: "10px",
                                                    marginBottom: "15px",
                                                    backgroundColor: "white",
                                                }}
                                            >
                                                <span>
                                                    <p
                                                        style={{
                                                            fontSize: "15px",
                                                            fontWeight: "bold",
                                                            color: "#52c41a",
                                                            display: "inline",
                                                            marginRight: "25px",
                                                        }}
                                                    >
                                                        Approved:{" "}
                                                        {
                                                            approvedSpecificDateStatistic
                                                        }{" "}
                                                        <UserOutlined />
                                                    </p>
                                                    <p
                                                        style={{
                                                            fontSize: "15px",
                                                            fontWeight: "bold",
                                                            color: "#ff9901",
                                                            display: "inline",
                                                        }}
                                                    >
                                                        Pending:{" "}
                                                        {
                                                            pendingSpecificDateStatistic
                                                        }{" "}
                                                        <UserOutlined />
                                                    </p>
                                                </span>
                                            </div>
                                            <List
                                                style={{
                                                    overflowX: "auto",
                                                    whiteSpace: "nowrap",
                                                }}
                                                itemLayout="horizontal"
                                                dataSource={
                                                    specificdateleavedata
                                                }
                                                renderItem={(item: any) => (
                                                    <List.Item className="hover-row">
                                                        {/* {role === "admin" && ( */}
                                                        {modalRendering(item)}
                                                        {/* // )} */}
                                                    </List.Item>
                                                )}
                                            />
                                        </Modal>
                                    )}
                                {(approvedSpecificMonthStatistic > 0 ||
                                    pendingSpecificMonthStatistic > 0) &&
                                    mode === "year" && (
                                        <Modal
                                            title={
                                                <div
                                                    style={{
                                                        padding: "15px",
                                                        backgroundColor:
                                                            "#343642",
                                                        color: "white",
                                                        minHeight: "25px",
                                                        maxHeight: "25px",
                                                    }}
                                                >
                                                    {selectedDate &&
                                                        moment(selectedDate)
                                                            .format("MMMM YYYY")
                                                            .toUpperCase()}
                                                </div>
                                            }
                                            visible={modalVisible}
                                            onCancel={handleModalClose}
                                            footer={null}
                                            centered
                                            width={1000}
                                            style={{ height: "550px" }}
                                            closeIcon={<CustomCloseIcon />}
                                            bodyStyle={{
                                                minHeight: 500,
                                                maxHeight: 500,
                                                overflow: "scroll",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginLeft: "10px",
                                                    marginTop: "10px",
                                                    marginBottom: "15px",
                                                    backgroundColor: "white",
                                                }}
                                            >
                                                <span>
                                                    <p
                                                        style={{
                                                            fontSize: "15px",
                                                            fontWeight: "bold",
                                                            color: "#52c41a",
                                                            display: "inline",
                                                            marginRight: "25px",
                                                        }}
                                                    >
                                                        Approved:{" "}
                                                        {
                                                            approvedSpecificMonthStatistic
                                                        }{" "}
                                                        <SolutionOutlined />
                                                    </p>
                                                    <p
                                                        style={{
                                                            fontSize: "15px",
                                                            fontWeight: "bold",
                                                            color: "#ff9901",
                                                            display: "inline",
                                                        }}
                                                    >
                                                        Pending:{" "}
                                                        {
                                                            pendingSpecificMonthStatistic
                                                        }{" "}
                                                        <SolutionOutlined />
                                                    </p>
                                                </span>
                                            </div>
                                            <List
                                                itemLayout="horizontal"
                                                dataSource={
                                                    specificmonthleavedata
                                                }
                                                renderItem={(item: any) => (
                                                    <List.Item className="hover-row">
                                                        {/* {role === "admin" && ( */}
                                                        {modalRendering(item)}
                                                        {/* // )} */}
                                                    </List.Item>
                                                )}
                                            />
                                        </Modal>
                                    )}
                            </div>
                        </div>
                    </Row>
                </Col>
                <Col lg={{ span: 24 }} xl={{ span: 8 }}>
                    <Row justify="center">
                        <h2 style={{ color: "black" }}>
                            Upcoming Public Holidays
                        </h2>
                    </Row>
                    <Row justify="center">
                        <div style={{ height: "550px" }}>
                            <Table
                                dataSource={filteredPHdata}
                                columns={columns}
                                size={isMobile ? "small" : "large"}
                                // scroll={{x:"100px", y:500}}
                                style={{
                                    padding: "20px",
                                    tableLayout: "fixed",
                                }}
                                pagination={paginationOptions}
                                rowClassName={highlightClosestDate(
                                    filteredPHdata.map(
                                        (item: any) => item.ph_date
                                    )
                                )}
                            />
                        </div>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default LeaveOverview;
