/**
 * 	Terra's main page, contains the Layout, Header, Footer, and Side Menu.
 *  Any other content will be available in the <Outlet/> element, which renders
 * 	components based on the current endpoint in the URL
 */

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { MainContext } from "../contexts/context";
import { Breadcrumb, Dropdown, Image, Input, Layout, Space, Spin } from "antd";
import Icon, { DownOutlined } from "@ant-design/icons";
import { Outlet, useNavigate } from "react-router";
// import elementalLogo from "../assets/image/elementalLogo.png";
import rajangLogo from "../assets/image/rajang logo bb.png";
import LoadingLogo from "../assets/image/swinburne.svg";
import LoadingOutline from "../assets/image/swinburne.svg";
import MainMenu from "../services/MainMenu";
import { GetAntIcon } from "../utils/ant_icons";
import Emitter from "../utils/emitter";
import file from "../../package.json";
import LeaveWithChangesModal from "../components/LeaveWithChangesModal";
import { color } from "d3";
import { isMobile } from "react-device-detect";
import { ArrowDropDownCircleOutlined } from "@mui/icons-material";

const { Header, Content, Footer } = Layout;

export const HomeContext = React.createContext({});

const Home = (props: any) => {
    const navigate = useNavigate();
    const userInfo = props?.userInfo.userRole[0];
    const formUpdatingRef = useRef<boolean>(false);
    const [menuProps, setMenuProps] = useState<any>([]);
    const [breadcrumb, setBreadcrumb] = useState<any>([
        {
            title: (
                <a onClick={() => handleBreadCrumbNavigate("/dashboard")}>
                    Home
                </a>
            ),
        },
    ]);
    const [isLoading, setLoading] = useState<any>(false);
    const [selectedKey, setSelectedKey] = useState<any>([]);
    const [isCollapsed, setCollapsed] = useState<boolean>(true);

    const [pageChange, setPageChange] = useState<boolean>(false);

    const handleBreadCrumbNavigate = async (link: any) => {
        try {
            await handlePageChange();
            navigate(link);
        } catch {}
    };

    useEffect(() => {
        Emitter.on("menuChange", (menuProps: any) => {
            if (
                localStorage.getItem("selectedFilters") &&
                menuProps.container !== "projectsOverview"
            ) {
                localStorage.removeItem("selectedFilters");
            }
            setMenuProps(menuProps);
        });

        Emitter.on("loading", () => {
            setLoading(true);
        });

        Emitter.on("finish-loading", () => {
            setLoading(false);
        });

        // Emitter on tab container (sorry Irham)
        Emitter.on("configuration", (directory: any) => {
            let breadcrumbList = breadcrumb.slice(0, 1);
            breadcrumbList.push({
                title: (
                    <a onClick={() => handleBreadCrumbNavigate("/dashboard")}>
                        Dashboard
                    </a>
                ),
            });
            breadcrumbList.push({ title: directory });
            setBreadcrumb(breadcrumbList);
        });
    }, []);

    useEffect(() => {
        let menu = props.menu;
        let currentlocation = "";
        // Previous menu key checks
        // if (props.currentLocation.endsWith("/")) {
        // 	let array = props.currentLocation.split("/");
        // 	currentlocation = array[array.length - 2];
        // } else {
        // 	currentlocation = props.currentLocation.split("/").pop();
        // }

        // current Temporary key check
        currentlocation = props.currentLocation.split("/");

        let menuItem = menu.find((element: any) => {
            // Previous Menu key checks, now to set it up differently
            // let route = element?.item?.to.split("/").pop();
            // return route === currentlocation;

            // Temporary check for current menu item location
            let route: any = element?.item?.to.split("/").pop();
            return currentlocation?.includes(route);
        });
        if (menuItem) {
            if (menuItem?.item?.requires_admin == true) {
                setMenuProps([{ title: "Administration" }, menuItem?.item]);
            } else {
                setMenuProps([menuItem?.item]);
            }

            setSelectedKey(menuItem.item.key);
        }
    }, [props.currentLocation]);

    useEffect(() => {
        let breadcrumbList: any;
        if (userInfo !== "admin") {
            breadcrumbList = [
                { title: <span style={{ color: "#ffffff" }}>Home</span> },
                //     {
                //         title: (
                //             <a onClick={() => handleBreadCrumbNavigate("/leave")}>
                //                 Home
                //             </a>
                //         ),
                //     },
            ];
        } else {
            breadcrumbList = [
                {
                    title: (
                        <a
                            onClick={() =>
                                handleBreadCrumbNavigate("/dashboard")
                            }
                        >
                            Home
                        </a>
                    ),
                },
            ];
        }

        // Set default breadcrumb for project config
        let locationArray = props.currentLocation.split("/");
        if (locationArray.includes("project-config")) {
            breadcrumbList.push(
                {
                    title: (
                        <a
                            onClick={() =>
                                handleBreadCrumbNavigate("/projects")
                            }
                        >
                            Projects
                        </a>
                    ),
                }
                // {
                // 	title: "Add new project",
                // }
            );
        }

        menuProps.forEach((menuItem: any, index: any) => {
            let breadcrumbItem: any = {};
            if (index == menuProps.length - 1 || !menuItem.to) {
                breadcrumbItem.title = menuItem.propTitle;
            } else if (menuItem.container === "projectsOverview") {
                breadcrumbItem.title = (
                    <a href={menuItem.to}>{menuItem.propTitle}</a>
                );
            } else {
                breadcrumbItem.title = (
                    <a href={menuItem.to}>{menuItem.propTitle}</a>
                );
            }
            breadcrumbList.push(breadcrumbItem);
        });

        // Temporary If condition fix for project-config
        if (!locationArray.includes("configuration")) {
            setBreadcrumb(breadcrumbList);
        }
    }, [menuProps]);

    let locations: string[] = window.location.pathname.split("/");
    locations.shift();

    let showBreadcrumb = true;
    if (locations.includes("documents")) {
        showBreadcrumb = false;
    }

    const onCollapse = (collapseState: any) => {
        setCollapsed(collapseState);
    };

    const handleFormUpdating = (bool: any) => {
        setPageChange(false);
        formUpdatingRef.current = bool;
    };

    const handlePageChange = () => {
        return new Promise((resolve, reject) => {
            setPageChange(true);
            if (formUpdatingRef.current) {
                Emitter.on("leave", () => {
                    resolve(true);
                    formUpdatingRef.current = false;
                    setPageChange(false);
                    Emitter.remove("leave");
                    Emitter.remove("stay");
                });
                Emitter.on("stay", () => {
                    reject(false);
                    setPageChange(false);
                    Emitter.remove("leave");
                    Emitter.remove("stay");
                });
            } else {
                resolve(true);
                setPageChange(false);
            }
        });
    };

    return (
        <MainContext.Consumer>
            {({ menuOption }) => {
                return (
                    <HomeContext.Provider
                        value={{
                            handleFormUpdating: handleFormUpdating,
                            handlePageChange: handlePageChange,
                        }}
                    >
                        <Layout
                            style={{ flexDirection: "row" }}
                            className="site-whole"
                        >
                            <MainMenu
                                collapsed={isCollapsed}
                                onCollapse={(collapseState: boolean) => {
                                    onCollapse(collapseState);
                                }}
                                selectedKey={selectedKey}
                                option={menuOption}
                                roles={props.userInfo.userRole}
                            ></MainMenu>
                            <Layout className="site-layout">
                                <Header className="site-header">
                                    {isMobile ? (
                                        <>
                                            <Breadcrumb
                                                items={breadcrumb}
                                                style={{
                                                    paddingLeft: "8%",
                                                    width: "auto",
                                                }}
                                            />
                                            {/* <div style={{ float: "left", paddingLeft: "20px"}}>
                                                <Dropdown trigger={["click"]}>
                                                    <DownOutlined />
                                                </Dropdown>
                                            </div> */}
                                        </>
                                    ) : (
                                        <Breadcrumb items={breadcrumb} />
                                    )}

                                    {/* <Input
										placeholder="Search"
										style={{ maxWidth: "265px" }}
										prefix={GetAntIcon("search")}
									/> */}
                                </Header>
                                <Content className="site-content">

                                    <Spin
                                        style={{ position: "fixed"}}
                                        size="large"
                                        tip={
                                            <div
                                                style={{
                                                    color: "white",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {/* <span style={{ fontSize: "30px" }}>{ModelLoadDesc}</span>
							<Progress style={{ width: "50%" }} percent={ModelLoadPercent} /> */}
                                            </div>
                                        }
                                        // indicator={
                                        // 	<div
                                        // 		style={{
                                        // 			display: "flex",
                                        // 			alignItems: "center",
                                        // 			justifyContent: "center",
                                        // 			top: 0,
                                        // 			insetInlineStart: 0,
                                        // 			height: "100%",
                                        // 			width: "100%",
                                        // 			margin: 0,
                                        // 		}}
                                        // 	>
                                        // 		<img
                                        // 			style={{ position: "absolute", width: "10%" }}
                                        // 			src={LoadingLogo}
                                        // 		/>
                                        // 		<img
                                        // 			className="spin"
                                        // 			style={{ position: "absolute", width: "15%" }}
                                        // 			src={LoadingOutline}
                                        // 		/>
                                        // 	</div>
                                        // }
                                        spinning={isLoading}
                                    >
                                        <Outlet />
                                    </Spin>
                                </Content>
                                {isMobile ? null : (
                                    <Footer className="site-footer">
                                        <span>Version {file.version}</span>
                                        <Space size={15}>
                                            <span>
                                                &copy; Rajang Digital Solutions
                                                Sdn. Bhd. 2024
                                            </span>
                                            <Image
                                                preview={false}
                                                width="80px"
                                                src={rajangLogo}
                                            />
                                        </Space>
                                    </Footer>
                                )}

                                <LeaveWithChangesModal
                                    isFormUpdating={formUpdatingRef.current}
                                    setFormUpdating={handleFormUpdating}
                                    pageChange={pageChange}
                                />
                            </Layout>
                        </Layout>
                    </HomeContext.Provider>
                );
            }}
        </MainContext.Consumer>
    );
};

Home.propTypes = {
    userInfo: PropTypes.any,
    currentLocation: PropTypes.any,
    menu: PropTypes.any,
};

export default Home;
