import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import {
    Button,
    Modal,
    Form,
    Input,
    DatePicker,
    Upload,
    message,
    Divider,
    Select,
    Cascader,
    Checkbox,
    Radio,
    Space,
    DatePickerProps,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { UploadOutlined } from "@ant-design/icons";

import Emitter from "../utils/emitter";
import { HomeContext } from "./Home";
import { v4 as uuidv4 } from "uuid";

import { getFormOptionList, getLookupItem } from "../utils/lookup_list";
import { addLeaveItem } from "../services/api-server/leave";
import { daysCalculation } from "../utils/utils";
import { deleteFile, uploadFile } from "../services/api-server/filetransfer";
import { get } from "lodash";
import { useWatch } from "antd/es/form/Form";
import { Moment } from "moment";
import moment from "moment";
import { sendEmail } from "../services/api-server/usertoken";
import { socket } from "../utils/socket";

const LeaveApplication = (props: any) => {
    const role = props.role;
    const userData = props.userData;
    const activeRowKey = props.activeRowKey;
    const showLeaveApplication = props.showLeaveApplication;
    const setShowLeaveApplication = props.setShowLeaveApplication;

    const formList = props.formList;
    const applicationform = props.leaveform;
    const imgNameUUIDList = props.imgNameUUIDList;
    const setImgNameUUIDList = props.setImgNameUUIDList;
    const workdayData = props.workdayData;
    const holidayData = props.holidayData;
    const dbData = props.dbData;
    const refreshData = props.refreshData;
    const setRefreshData = props.setRefreshData;
    const [dateRange, setDateRange] = useState<any>([]);
    const [cascaderVisibility, setCascaderVisibility] = useState(false);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [attachmentRequired, setAttachmentRequired] = useState<any>(false);
    const [useraccData, setUseraccData] = useState<any>([]);
    const [leavetypeData, setLeavetypeData] = useState<any>([]);
    // const handleFinish = props.handleFinish;
    // const handleCancel = props.handleCancel;
    // const handleReset = props.handleReset;

    // const [showLeaveApplication, setShowLeaveApplication] = useState<any>(true);
    const [forceModalRenderer, setForceModalRenderer] = useState<any>(0);

    const { RangePicker } = DatePicker;

    // Get the current year
    const currentYear = new Date().getFullYear();

    const url = process.env.REACT_APP_SOCKET_SERVER as string;

    async function handleSendEmail(
        applicationformValues: any,
        content: any,
        useraccData: any
    ) {
        sendEmail(applicationformValues, content, useraccData)
            .then((response: any) => {
                console.log("Email sent successfully:", response);
            })
            .catch((error: any) => {
                console.error("Error sending email:", error);
            });
    }

    function compareDates(date1: string, date2: string): number {
        let [day1, month1, year1] = date1.slice(0, -4).split("/").map(Number);
        let [day2, month2, year2] = date2.slice(0, -4).split("/").map(Number);

        // Compare years
        // year1 = Number(year1.toString().slice(0, 4));
        // year2 = Number(year2.toString().slice(0, 4));
        if (year1 !== year2) {
            return year1 - year2;
        }
        // Compare months
        if (month1 !== month2) {
            return month1 - month2;
        }
        // Compare days
        return day1 - day2;
    }

    useEffect(() => {
        // console.log("Test form:", dbData);
        // console.log("test holiday", holidayData, workdayData);
        applicationform.setFieldsValue({
            status: "pending",
            year: currentYear,
            // employee_id: userData._id.toString(),
        });
        // if (cascaderVisibility === false) {
        //     applicationform.setFieldValue("half_days", null);
        //     setSelectedItems([]);
        // }
    }, [refreshData, cascaderVisibility]);

    useEffect(() => {
        getLookupItem("useracc").then((useraccData: any) => {
            const adminData = useraccData
                .filter((data: any) => data.roles[0] === "admin")
                .map((filteredData: any) => {
                    return filteredData.email;
                });
            setUseraccData(adminData);
        });

        getLookupItem("leavetype").then((leavetypeData: any) => {
            setLeavetypeData(
                leavetypeData.reduce((acc: any, data: any) => {
                    acc[data.leave_type_code] = { ...data };
                    return acc;
                }, {})
            );
        });
    }, []);

    const handleDateRangeChange = (dates: any) => {
        applicationform.setFieldValue("half_days", []);
        setSelectedItems([]);
        if (dates) {
            setDateRange(dates);
        }
    };

    const onCheckboxClick: any = (e: any) => {
        //console.log(e.target.checked);
        if (e.target.checked === true) {
            setCascaderVisibility(true);
            handleFormChange();
        } else {
            setCascaderVisibility(false);
            applicationform.setFieldValue("half_days", []);
            setSelectedItems([]);
            handleFormChange();
        }
    };

    const filterDates = (date: Moment) => {
        // Check if the date is not an off day
        let isNotOffDay = true;
        let isNotHoliday = true;
        for (const day of workdayData) {
            if (day.weekday === date.format("dddd")) {
                if (day.workperiod === "offday") {
                    isNotOffDay = false;
                }
                break;
            }
        }

        // Check if the date is not a public holiday
        for (const holiday of holidayData) {
            let newholiday = new Date(holiday.ph_date)
                .toLocaleString()
                .split(", ")[0];
            if (newholiday === date.format("M/D/YYYY")) {
                isNotHoliday = false;
                break;
            }
        }

        // isNotHoliday = holidayData.every((holiday: any) => {
        //  //console.log("Test:", holiday.ph_date, date.format("DD MMM YYYY"));
        //     return holiday.ph_date !== date.format("DD MMM YYYY");
        // });
        return isNotOffDay && isNotHoliday;
    };

    const filterOption = (input: any, option: any) => {
        return option.label.toLowerCase().includes(input.toLowerCase());
    };

    const getOptions = () => {
        if (dateRange.length !== 2) {
            return [];
        }

        const [startDate, endDate] = dateRange;
        const options = [];
        // let currentDate = startDate.clone();
        let currentDate = moment(startDate.toDate());
        let finalDate = moment(endDate.toDate());
        while (currentDate.isSameOrBefore(finalDate)) {
            if (filterDates(currentDate)) {
                const dateString = currentDate.format("DD MMM YYYY");
                options.push(`${dateString} (AM)`, `${dateString} (PM)`);
            }
            currentDate.add(1, "day");
        }
        return options;
    };

    let filteredOptions = getOptions().filter((o) => {
        return selectedItems?.every(
            (item: any) => !item.includes(o.slice(0, -4))
        );
    });

    const handleTemporaryOptions = (caseName: any) => {
        const getMappedArray = (caseName: any) => {
            switch (caseName) {
                case "status":
                    if (role === "admin") {
                        return [
                            {
                                key: "pending",
                                label: "Pending",
                                value: "pending",
                            },
                            {
                                key: "approved",
                                label: "Approved",
                                value: "approved",
                            },
                            {
                                key: "rejected",
                                label: "Rejected",
                                value: "rejected",
                            },
                        ];
                    } else {
                        return [
                            {
                                key: "pending",
                                label: "Pending",
                                value: "pending",
                            },
                        ];
                    }
                case "employee_id":
                    return dbData?.map((item: any) => {
                        return {
                            // key: item.staff_id_num + " - " + item.fullname_nric,
                            key: item._id,
                            label:
                                item.staff_id_num + " - " + item.fullname_nric,
                            value: item._id.toString(),
                        };
                    });
                case "year":
                    // Generate an array of 5 years from the current year (For Generate Annual Report function)
                    const years = Array.from(
                        { length: 5 },
                        (_, index) => currentYear - index
                    );
                    return years?.map((year: any) => {
                        return {
                            key: year,
                            label: year,
                            value: year,
                        };
                    });
                case "leave_type":
                    // if (applicationform.getFieldValue("employee_id")) {
                    // const employee_id =
                    //     applicationform.getFieldValue("employee_id");
                    if (dbData) {
                        // return [];
                        const employee_id = userData._id;
                        let leave_entitlement = dbData
                            .filter((item: any) => item._id == employee_id)
                            .map((item: any) => item.leave_entitled);
                        return Object.keys(leave_entitlement[0]).map(
                            (item: any) => {
                                const leave_type =
                                    leave_entitlement[0][item].leave_type
                                        .charAt(0)
                                        .toUpperCase() +
                                    leave_entitlement[0][item].leave_type.slice(
                                        1
                                    ) +
                                    " Leave";
                                const leave_balance =
                                    leave_entitlement[0][item].entitled -
                                    leave_entitlement[0][item].used;
                                return {
                                    key: leave_entitlement[0][item].leave_type,
                                    label:
                                        leave_type +
                                        " ( Balance: " +
                                        leave_balance +
                                        " )",
                                    value: leave_entitlement[0][item]
                                        .leave_type,
                                };
                            }
                        );
                    } else {
                        return [];
                    }
                default:
                    return [];
            }
        };
        return getMappedArray(caseName);
    };

    const setInputType = (element: any, index: any) => {
        switch (element.type) {
            case "input":
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                    >
                        <Input
                            autoFocus={index == 0 && !activeRowKey}
                            autoComplete="off"
                            onChange={(event: any) => {
                                const value = event.target.value
                                    .replace(/^ /, "")
                                    .replace(/\s+/g, " ");
                                applicationform.setFieldValue(
                                    element.name,
                                    value
                                );
                                // setFormReset(false);
                            }}
                            style={{
                                width: element.width
                                    ? `${element.width * 4}%`
                                    : "100%",
                                borderColor: element.borderColor || "#000000",
                            }}
                        ></Input>
                    </Form.Item>
                );
            case "textArea":
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                    >
                        <TextArea
                            autoFocus={index == 0 && !activeRowKey}
                            onChange={() => {
                                // setFormReset(false);
                            }}
                            style={{
                                width: element.width
                                    ? `${element.width * 4}%`
                                    : "100%",
                                borderColor: element.borderColor || "#000000",
                            }}
                        ></TextArea>
                    </Form.Item>
                );
            case "gridx":
                return (
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: element.gridLength
                                ? element.gridLength
                                      .map((length: any) => `${length}fr`)
                                      .join(" ")
                                : `repeat(${element.children.length}, 1fr)`,
                            gap: `${element.gap}`,
                        }}
                    >
                        {element.children.map((child: any, index: any) =>
                            setInputType(child, index)
                        )}
                    </div>
                );

            case "gridy":
                return (
                    <div
                        style={{
                            display: "grid",
                            gridTemplateRows: element.gridLength
                                ? element.gridLength
                                      .map((length: any) => `${length}fr`)
                                      .join(" ")
                                : `repeat(${element.children.length}, 1fr)`,
                            gap: `${element.gap}`,
                        }}
                    >
                        {element.children.map((child: any, index: any) =>
                            setInputType(child, index)
                        )}
                    </div>
                );
            case "disabled":
                return (
                    <Form.Item
                        required={false}
                        label={element.label}
                        name={element.name}
                    >
                        <Input
                            disabled
                            style={{
                                width: element.width
                                    ? `${element.width * 4}%`
                                    : "100%",
                                borderColor: element.borderColor || "#000000",
                                fontWeight: "bold",
                            }}
                        ></Input>
                    </Form.Item>
                );
            case "divider":
                return (
                    <Divider
                        style={{ margin: 0, color: "rgba(255,255,255,0.45)" }}
                        key={element.label}
                        orientation="left"
                    >
                        {element.label}
                    </Divider>
                );
            case "datePicker":
                const customFormat: DatePickerProps["format"] = (value: any) =>
                    `${value.format("DD MMM YYYY")}`;
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                    >
                        <RangePicker
                            // placement="topRight"
                            // popupStyle={{height:"100%", overflow:"scroll"}}
                            style={{
                                width: element.width
                                    ? `${element.width * 4}%`
                                    : "100%",
                                borderColor: element.borderColor || "#000000",
                            }}
                            // disabledDate={(current: any) => {
                            //     return current && current < new Date();
                            // }}
                            onChange={handleDateRangeChange}
                            format={customFormat}
                        ></RangePicker>
                    </Form.Item>
                );

            case "empty":
                return <div key={index}></div>;
            case "select-temporary":
                // dataObject = filteredExtraData || dataTable;
                let dataObject: any = dbData;
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                    >
                        <Select
                            defaultValue={element.default}
                            placeholder={get(dataObject, element.name, "")}
                            autoFocus={index == 0 && !activeRowKey}
                            allowClear
                            filterOption={filterOption}
                            showSearch
                            options={handleTemporaryOptions(element.case)}
                            mode={element.mode || undefined}
                            onChange={() => {
                                setForceModalRenderer(forceModalRenderer + 1);
                            }}
                            style={{
                                width: element.width
                                    ? `${element.width * 4}%`
                                    : "100%",
                                borderColor: element.borderColor || "#000000",
                            }}
                        ></Select>
                    </Form.Item>
                );
            case "days-calculation":
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                    >
                        <Input
                            disabled
                            style={{
                                width: element.width
                                    ? `${element.width * 4}%`
                                    : "100%",
                                borderColor: element.borderColor || "#000000",
                            }}
                        ></Input>
                    </Form.Item>
                );
            case "leave-calculation":
                return (
                    <Form.Item
                        // label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                    >
                        <TextArea
                            disabled
                            autoSize
                            autoFocus={index == 0 && !activeRowKey}
                            onChange={() => {
                                // setFormReset(false);
                            }}
                            style={{
                                width: element.width
                                    ? `${element.width * 4}%`
                                    : "100%",
                                resize: "none",
                                borderColor:
                                    element.borderColor || "rgba(0,0,0,0)",
                                // borderColor: element.borderColor || "#000000",
                            }}
                        ></TextArea>
                    </Form.Item>
                );
            case "select":
                if (cascaderVisibility) {
                    return (
                        <Form.Item label={element.label} name={element.name}>
                            <Select
                                mode="multiple"
                                placeholder="No half days"
                                value={selectedItems}
                                onChange={setSelectedItems}
                                allowClear
                                style={{ width: "100%" }}
                                options={filteredOptions.map((item: any) => ({
                                    value: item,
                                    label: item,
                                }))}
                            />
                        </Form.Item>
                    );
                } else {
                    return null;
                }
            case "checkbox":
                return (
                    <Form.Item label={element.label} name={element.name}>
                        <Checkbox
                            style={{ width: "100%" }}
                            onChange={onCheckboxClick}
                        >
                            Yes
                        </Checkbox>
                    </Form.Item>
                );
            case "upload-attachments":
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: attachmentRequired, message: "" }]}
                    >
                        <Upload.Dragger
                            name="file"
                            listType="picture"
                            customRequest={async (info: any) => {
                                const formData = new FormData();
                                formData.append("file", info.file);
                                const filename = info.file.name;

                                const imgUUID =
                                    uuidv4() + "." + filename.split(".").pop();
                                formData.append("uuid", imgUUID);
                                setImgNameUUIDList([
                                    ...imgNameUUIDList,
                                    { name: filename, uuid: imgUUID },
                                ]);
                                // formData.append("folder_name", "leave");

                                uploadFile(formData)
                                    .then((data: any) => {
                                        info.onSuccess(data, info.file);
                                    })
                                    .catch((error: any) => {
                                        info.onError(error, info.file);
                                    });
                            }}
                            accept=".png, .jpg, .jpeg, .pdf, .docx"
                            headers={{
                                authorization: "authorization-text",
                            }}
                            // onChange={(info: any) => {
                            //     if (info.file.status !== "uploading") {
                            //         //console.log(info.file, info.fileList);
                            //     }
                            //     if (info.file.status === "done") {
                            //         message.success(
                            //             `${info.file.name} file uploaded successfully`
                            //         );
                            //     } else if (info.file.status === "error") {
                            //         message.error(
                            //             `${info.file.name} file upload failed.`
                            //         );
                            //     }
                            // }}
                            style={{
                                width: element.width
                                    ? `${element.width * 4}%`
                                    : "100%",
                                borderColor: element.borderColor || "#000000",
                            }}
                        >
                            <Button icon={<UploadOutlined />}>
                                Click to upload
                            </Button>
                        </Upload.Dragger>
                    </Form.Item>
                );
            default:
                return <Input disabled></Input>;
        }
    };

    const handleOk = () => {
        if (
            applicationform.getFieldValue("days") <= 0 ||
            applicationform.getFieldValue("leave_calculation") < 0
        ) {
            Emitter.emit("alert", {
                type: "error",
                title: "Error",
                message:
                    "Leave application failed to submit. Please ensure leave balance is sufficient and leave duration is appropriate.",
                description: "",
                top: true,
                closeable: false,
                timeout: 3000,
            });
        } else if (
            attachmentRequired &&
            !applicationform.getFieldValue("attachment")
        ) {
            Emitter.emit("alert", {
                type: "error",
                title: "Error",
                message:
                    "Leave application failed to submit. Please upload a file as an attachment and fill in the required fields.",
                description: "",
                top: true,
                closeable: false,
                timeout: 3000,
            });
        } else {
            if (
                applicationform.getFieldValue("leave_type") &&
                applicationform.getFieldValue("days") &&
                applicationform.getFieldValue("days") > 0 &&
                applicationform.getFieldValue("leave_calculation") >= 0
            ) {
                const applicationformValues = applicationform.getFieldsValue();
                // console.log("k-leaveformValues", leaveformValues);

                ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
                // format the date to ISO string
                // applicationformValues.applied_date = moment(
                //     new Date().toLocaleString().split(", ")[0]
                // ).format("DD MMM YYYY");

                // applicationformValues.start_date = moment(
                //     new Date(applicationformValues.date_range[0])
                //         .toLocaleString()
                //         .split(", ")[0]
                // ).format("DD MMM YYYY");

                // applicationformValues.end_date = moment(
                //     new Date(applicationformValues.date_range[1])
                //         .toLocaleString()
                //         .split(", ")[0]
                // ).format("DD MMM YYYY");

                applicationformValues.applied_date =
                    moment().format("YYYY-MM-DD");
                const startDate = moment(
                    applicationformValues.date_range[0].toDate()
                ).format("YYYY-MM-DD");
                const endDate = moment(
                    applicationformValues.date_range[1].toDate()
                ).format("YYYY-MM-DD");
                applicationformValues.start_date = startDate;
                applicationformValues.end_date = endDate;
                // applicationformValues.end_date = moment(
                //     applicationformValues.date_range[1]
                // ).format("DD MMM YYYY");

                // Old formatting of date

                // applicationformValues.applied_date = new Date()
                //     .toISOString()
                //     .slice(0, 19)
                //     .replace("T", " ");
                // applicationformValues.start_date =
                //     applicationformValues.date_range[0]
                //         .toISOString()
                //         .slice(0, 19)
                //         .replace("T", " ");

                // applicationformValues.end_date = applicationformValues.date_range[1]
                //     .toISOString()
                //     .slice(0, 19)
                //     .replace("T", " ");

                if (applicationformValues.attachment != undefined) {
                    const attachmentList = [];
                    for (
                        let i = 0;
                        i < applicationformValues.attachment.fileList.length;
                        i++
                    ) {
                        const fileName =
                            applicationformValues.attachment.fileList[i].name;
                        const uuid = imgNameUUIDList.find(
                            (item: any) => item.name === fileName
                        )?.uuid;

                        attachmentList.push({ name: fileName, uuid: uuid });
                        imgNameUUIDList.splice(
                            imgNameUUIDList.findIndex(
                                (item: any) => item.name === fileName
                            ),
                            1
                        );

                        // leaveformValues.attachment = leaveformValues.attachment.fileList[0].name;
                    }
                    applicationformValues.attachment = attachmentList;
                    if (imgNameUUIDList.length > 0) {
                        applicationformValues.deleteAttachment =
                            imgNameUUIDList;
                    }
                }
                applicationformValues.employee_id = userData._id;
                if (applicationformValues.half_days?.length > 0) {
                    applicationformValues.half_days =
                        applicationformValues.half_days.sort(compareDates);
                    // if (applicationformValues.half_days.length === 0) {
                    //     delete applicationformValues.half_days;
                    // }
                } else {
                    delete applicationformValues.half_days;
                }

                applicationformValues.status = "pending";

                // remove the data from the leaveformValues
                delete applicationformValues.days;
                delete applicationformValues.leave_calculation;
                delete applicationformValues.half_days_option;
                // delete leaveformValues.attachment;
                //console.log("Received values of form 1:", applicationformValues);
                const user = dbData?.find(
                    (data: any) =>
                        data._id === applicationformValues.employee_id
                );
                // const userEmail = user.email;
                // console.log("admin emails", useraccData);
                ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
                const content = `Dear Admin,\n\n${user.fullname_nric} has submitted a leave application. Please review the leave application at ${url}\n\nThank you.\n\nBest regards,\nRajang Digital Solutions HR Team`;
                addLeaveItem(applicationformValues)
                    .then((_data: any) => {
                        Emitter.emit("alert", {
                            type: "success",
                            title: "Success",
                            message: "Leave application submitted.",
                            description: "",
                            top: true,
                            closeable: false,
                            timeout: 3000,
                        });
                        socket.emit("pong-update", () => {
                            // const duration = Date.now() - start;
                            console.log("Pinging server");
                        });
                    })
                    .catch((error: any) => {
                        //console.log("error", error);
                        Emitter.emit("alert", {
                            type: "error",
                            title: "Error",
                            message: "Leave application failed to submit.",
                            description: "",
                            top: true,
                            closeable: false,
                            timeout: 3000,
                        });
                    })
                    .finally(() => {
                        setShowLeaveApplication(false);
                        setAttachmentRequired(false);
                        applicationform.resetFields();
                        setDateRange([]);
                        filteredOptions = [];
                        setCascaderVisibility(false);
                        setImgNameUUIDList([]);
                        // setRefreshData(!refreshData);
                        handleSendEmail(
                            applicationformValues,
                            content,
                            useraccData
                        );
                    });
            } else {
                Emitter.emit("alert", {
                    type: "error",
                    title: "Error",
                    message:
                        "Leave application failed to submit. Please fill in the required fields.",
                    description: "",
                    top: true,
                    closeable: false,
                    timeout: 3000,
                });
            }
        }
    };

    const handleFormChange = () => {
        const changedValues = applicationform.getFieldsValue();
        if (leavetypeData[changedValues.leave_type]?.attachment_required) {
            setAttachmentRequired(true);
        } else {
            setAttachmentRequired(false);
        }

        if (changedValues.date_range) {
            const startDate = applicationform.getFieldValue("date_range")[0];
            const endDate = applicationform.getFieldValue("date_range")[1];
            const workingDays: any = daysCalculation(
                workdayData,
                holidayData,
                startDate,
                endDate,
                changedValues.half_days
            );

            applicationform.setFieldsValue({ days: workingDays });
            if (userData._id && changedValues.leave_type) {
                const leavetype = changedValues.leave_type;
                // const leave_entitled = dbData
                //     .filter((item: any) => item._id == userData._id)
                //     .map((item: any) => item.leave_entitled)[0][
                //     leavetype
                // ].entitled;
                // const leave_used = dbData
                //     .filter((item: any) => item._id == userData._id)
                //     .map((item: any) => item.leave_entitled)[0][leavetype].used;
                let leave_entitled;
                let leave_used;

                for (const item of dbData) {
                    // console.log("test", item, leavetype);
                    if (item._id === userData._id) {
                        const leaveTypeData = item.leave_entitled.find(
                            (entry: any) => entry.leave_type === leavetype
                        );
                        // console.log("Testt", leaveTypeData);
                        if (leaveTypeData) {
                            leave_entitled = leaveTypeData.entitled;
                            leave_used = leaveTypeData.used;
                            break;
                        }
                    }
                }
                // console.log("test", leave_entitled, leave_used);
                applicationform.setFieldsValue({
                    leave_calculation:
                        leave_entitled - leave_used - workingDays,
                });

                if (cascaderVisibility && changedValues.half_days?.length > 0) {
                    // changedValues.half_days = changedValues.half_days.filter(
                    //     (item: any) => item.length > 1
                    // );
                    // const updatedworkingDays =
                    //     workingDays - 0.5 * changedValues.half_days.length;
                    applicationform.setFieldsValue({
                        days: workingDays,
                        leave_calculation:
                            leave_entitled - leave_used - workingDays,
                    });
                }
            }
        } else {
            applicationform.setFieldsValue({ days: null });
            applicationform.setFieldsValue({ leave_calculation: null });
        }
    };

    const handleCancel = () => {
        if (imgNameUUIDList.length > 0) {
            imgNameUUIDList.map((item: any) => {
                deleteFile(item.uuid, "leave_attachments").then((data: any) => {
                    console.log("File deleted from server");
                    // console.log("File deleted successfully:", data);
                });
            });
        }
        setShowLeaveApplication(false);
    };

    const handleReset = () => {
        applicationform.resetFields();
        setDateRange([]);
        filteredOptions = [];
        setSelectedItems([]);
        setAttachmentRequired(false);
        setCascaderVisibility(false);
    };

    return (
        <Modal
            title="New Leave Application"
            open={showLeaveApplication}
            onOk={handleOk}
            onCancel={handleCancel}
            className="rejection-modal"
            width={1000}
            footer={[
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Button key="submit" onClick={handleReset}>
                        Reset
                    </Button>
                    <div>
                        <Button
                            key="back"
                            onClick={handleCancel}
                            style={{ marginRight: "8px", borderRadius: "3px" }} // Add margin-right for spacing
                        >
                            Cancel
                        </Button>
                        <Button
                            key="submit"
                            type="primary"
                            onClick={handleOk}
                            style={{ borderRadius: "3px" }}
                        >
                            Submit
                        </Button>
                    </div>
                </div>,
            ]}
        >
            <>
                <Form
                    layout="vertical"
                    form={applicationform}
                    requiredMark={true}
                    onValuesChange={handleFormChange}
                    name="leave_application_form"
                    style={{
                        gap: "10px",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    {formList?.map((item: any, index: number) => {
                        return setInputType(item, index);
                    })}
                </Form>
            </>
        </Modal>
    );
};

export default LeaveApplication;
