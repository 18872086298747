import React, { useEffect, useState, useRef, useContext } from "react";
import { useForm, useWatch } from "antd/es/form/Form";
import PropTypes from "prop-types";
import {
    Form,
    Input,
    Select,
    Tag,
    Divider,
    ColorPicker,
    Button,
    DatePicker,
    Space,
    Upload,
    Typography,
    ConfigProvider,
    Popconfirm,
    Checkbox,
    DatePickerProps,
    message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { getFormOptionList, getLookupItem } from "../utils/lookup_list";
import {
    deleteFile,
    downloadFile,
    uploadFile,
} from "../services/api-server/filetransfer";
import { GetAntIcon } from "../utils/ant_icons";
import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { get } from "lodash";
import { daysCalculation } from "../utils/utils";
import moment from "moment";
import { active, color } from "d3";
import FormButtonCancel from "./FormButtonCancel";
import FormButtonSave from "./FormButtonSave";
import Emitter from "../utils/emitter";
import { HomeContext } from "../containers/Home";
import {
    addPositionItem,
    deletePositionItem,
    getPositionList,
    updatePositionItem,
} from "../services/api-server/position";
import { getLeaveList } from "../services/api-server/leave";
import {
    addLeaveTypeItem,
    deleteLeaveTypeItem,
    getLeaveTypeList,
    updateLeaveTypeItem,
} from "../services/api-server/leavetype";
import dayjs from "dayjs";
import {
    addHolidayItem,
    deleteHolidayItem,
    updateHolidayItem,
} from "../services/api-server/publicholiday";
import {
    deleteWorkdayItem,
    updateWorkdayItem,
} from "../services/api-server/workdays";
import { updateEmployeeItem } from "../services/api-server/employee";
import { socket } from "../utils/socket";
import { isMobile } from "react-device-detect";
import { v4 as uuidv4 } from "uuid";
import {
    addPolicyItem,
    deletePolicyItem,
    getPolicyList,
    updatePolicyFileItem,
    updatePolicyItem,
} from "../services/api-server/policy";
import { ContactSupportOutlined } from "@mui/icons-material";
const { Text } = Typography;

const AdministrationCustomForm = (props: any) => {
    const data = props.data || [];
    const context: any = useContext(HomeContext);
    //     const initialForm = useRef(null);
    const [initialForm, setInitialForm] = useState<any>(null);
    const [formRef] = useForm();
    const formValues = useWatch(formRef);
    // const formRef = props.formRef;
    const [formReset, setFormReset] = useState<boolean>(true);
    const activeRowKey = props?.activeRowKey;
    const setActiveRowKey = props?.setActiveRowKey;
    //     const setFormReset = props?.setFormReset;
    const tabKey = props?.tabKey;
    const subTabKey = props?.subTabKey;
    const employeeData = props?.employeeData;
    const holidayData = props?.holidayData;
    const workdayData = props?.workdayData;
    const refreshData = props?.refreshData;
    const setRefreshData = props?.setRefreshData;
    const setSideContainerOpen = props?.setSideContainerOpen;
    const sideContainerOpen = props?.sideContainerOpen;
    const [emptyModalOpen, setEmptyModalOpen] = useState<any>(null);
    const [popOverVisibility, setPopOverVisibility] = useState<boolean>(false);
    const [checkboxValue, setCheckboxValue] = useState<any>(null);
    const [imgNameUUIDList, setImgNameUUIDList] = useState<any>([]);
    const [prevUUID, setPrevUUID] = useState<any>("");
    const currentDate = new Date();
    const [filelist, setFilelist] = useState<any>([]);

    // UseEffect
    const fetchData = () => {
        if (subTabKey === "leavetype") {
            getLeaveTypeList().then((leavetypedata: any) => {
                const leavetype = leavetypedata?.find(
                    (data: any) => data._id === activeRowKey
                );
                if (leavetype) {
                    // console.log("leavetype data", leavetype);
                    if (leavetype.attachment_required) {
                        setCheckboxValue(true);
                    } else {
                        setCheckboxValue(false);
                    }
                    setInitialForm(data);
                    formRef.setFieldsValue(data);
                }
            });
        } else if (subTabKey === "holiday") {
            if (data.ph_date) {
                data.ph_date = dayjs(data.ph_date);
                setInitialForm(data);
                formRef.setFieldsValue(data);
                formRef.setFieldValue("ph_date", dayjs(data.ph_date));
            }
        } else if (subTabKey === "policy") {
            if (data.uuid) {
                data.last_updated = dayjs(data.last_updated);
                setPrevUUID(data.uuid);
                setInitialForm(data);
                formRef.setFieldsValue(data);
            }
        } else {
            if (activeRowKey) {
                setInitialForm(data);
                formRef.setFieldsValue(data);
            }
        }
    };

    // UseEffect to set field values
    useEffect(() => {
        fetchData();
        socket.on("refresh-leave-data", () => {
            fetchData();
        });
    }, [activeRowKey, data, refreshData]);

    // Reset form
    const resetForm = (values: any = null, data: any = null) => {
        if (formRef) {
            setFormReset(true);
            formRef.resetFields();
            formRef.setFieldsValue(values);
            setInitialForm(values);
        }
    };

    // Handle form change
    const handleFormChange = () => {
        setFormReset(false);
        //   const values = formRef.getFieldsValue();
        formRef.setFieldsValue(formValues);
    };

    // Handle form save
    const handleFormSave = (
        formValues: any | null = null,
        type: any = null
    ) => {
        let values = formValues || formRef.getFieldsValue();

        // For each values in the form, trim the leading and trailing spaces
        Object.keys(values).forEach((key) => {
            if (typeof values[key] == "string") {
                values[key] = values[key].trim();
            }
        });

        Emitter.emit("loading", null);

        if (!activeRowKey) {
            switch (subTabKey) {
                case "position":
                    // console.log("values", values);
                    values.status = "active";
                    addPositionItem(values)
                        .then((data: any) => {
                            Emitter.emit("alert", {
                                type: "success",
                                message:
                                    "Position has been successfully added.",
                                description: "",
                                top: true,
                                closeable: false,
                                timeout: 3000,
                            });
                            setSideContainerOpen(false);
                        })
                        .catch((e: any) => {
                            //console.log("error", e);
                            resetForm(values);
                            Emitter.emit("alert", {
                                type: "error",
                                message:
                                    e.response.data.detail +
                                    ". Please try again.",
                                description: "",
                                top: true,
                                closeable: false,
                                timeout: 3000,
                            });
                        })
                        .finally(() => {
                            Emitter.emit("finish-loading", null);
                            setRefreshData(!refreshData);
                            context?.handleFormUpdating(false);
                            socket.emit("pong-update", () => {
                                console.log("Pinging server");
                            });
                        });
                    break;
                case "leavetype":
                    // console.log("values", values);
                    values.status = "active";
                    addLeaveTypeItem(values)
                        .then((data: any) => {
                            Emitter.emit("alert", {
                                type: "success",
                                message:
                                    "Leave type has been successfully added.",
                                description: "",
                                top: true,
                                closeable: false,
                                timeout: 3000,
                            });
                            if (employeeData) {
                                for (const employee of employeeData) {
                                    employee.leave_entitled = [
                                        ...employee.leave_entitled,
                                        {
                                            leave_type: values.leave_type_code,
                                            entitled: Number(
                                                values.default_days
                                            ),
                                            used: 0,
                                        },
                                    ];
                                    updateEmployeeItem([employee._id], employee)
                                        .then((data: any) => {
                                            console.log(
                                                "employee data updated suceesfully"
                                            );
                                        })
                                        .catch((error: any) => {
                                            console.log("error", error);
                                        });
                                }
                            } else {
                                // console.log("Test employee", employeeData);
                            }
                            setSideContainerOpen(false);
                        })
                        .catch((e: any) => {
                            //console.log("error", e);
                            resetForm(values);
                            Emitter.emit("alert", {
                                type: "error",
                                message:
                                    e.response.data.detail +
                                    ". Please try again.",
                                description: "",
                                top: true,
                                closeable: false,
                                timeout: 3000,
                            });
                        })
                        .finally(() => {
                            Emitter.emit("finish-loading", null);
                            setRefreshData(!refreshData);
                            context?.handleFormUpdating(false);
                            socket.emit("pong-update", () => {
                                console.log("Pinging server");
                            });
                        });
                    break;
                case "holiday":
                    // console.log("values", values);
                    values.ph_date = moment(values.ph_date.toDate()).format(
                        "YYYY-MM-DD"
                    );
                    addHolidayItem(values)
                        .then((data: any) => {
                            Emitter.emit("alert", {
                                type: "success",
                                message: "Holiday has been successfully added.",
                                description: "",
                                top: true,
                                closeable: false,
                                timeout: 3000,
                            });
                            values.ph_date = dayjs(values.ph_date);
                            setSideContainerOpen(false);
                            resetForm(values);
                        })
                        .catch((e: any) => {
                            //console.log("error", e);
                            values.ph_date = dayjs(values.ph_date);
                            resetForm(values);
                            Emitter.emit("alert", {
                                type: "error",
                                message:
                                    e.response.data.detail +
                                    ". Please try again.",
                                description: "",
                                top: true,
                                closeable: false,
                                timeout: 3000,
                            });
                        })
                        .finally(() => {
                            Emitter.emit("finish-loading", null);
                            setRefreshData(!refreshData);
                            context?.handleFormUpdating(false);
                            socket.emit("pong-update", () => {
                                console.log("Pinging server");
                            });
                        });
                    break;

                case "policy":
                    if (values.uuid) {
                        // console.log("uuid", values.uuid);
                        let policyList: any = {};
                        const filename = values.uuid.fileList
                            ? values.uuid.fileList[0].name
                            : values.filename;

                        const fileUUID =
                            imgNameUUIDList.find(
                                (item: any) => item.name === filename
                            )?.uuid || values.uuid;
                        values.last_updated = moment(
                            values.last_updated.toDate()
                        ).format("YYYY-MM-DD");
                        policyList = {
                            ...values,
                            filename: filename,
                            uuid: fileUUID,
                        };

                        addPolicyItem(policyList)
                            .then((data: any) => {
                                updatePolicyFileItem(
                                    [activeRowKey],
                                    policyList
                                ).then((policyData: any) => {
                                    Emitter.emit("alert", {
                                        type: "success",
                                        message:
                                            "Policy has been successfully added.",
                                        description: "",
                                        top: true,
                                        closeable: false,
                                        timeout: 3000,
                                    });
                                    values.last_updated = dayjs(
                                        values.last_updated
                                    );
                                    setSideContainerOpen(false);
                                    setImgNameUUIDList([]);
                                    resetForm(values);
                                });
                            })
                            .catch((e: any) => {
                                //console.log("error", e);
                                values.last_updated = dayjs(
                                    values.last_updated
                                );
                                resetForm(values);
                                Emitter.emit("alert", {
                                    type: "error",
                                    message:
                                        e.response.data.detail +
                                        ". Please try again.",
                                    description: "",
                                    top: true,
                                    closeable: false,
                                    timeout: 3000,
                                });
                            })
                            .finally(() => {
                                Emitter.emit("finish-loading", null);
                                setRefreshData(!refreshData);
                                context?.handleFormUpdating(false);
                                socket.emit("pong-update", () => {
                                    console.log("Pinging server");
                                });
                            });
                    }
                    break;
            }
        } else {
            switch (subTabKey) {
                case "position":
                    // console.log("values", values);
                    values.status = "active";
                    updatePositionItem([activeRowKey], values)
                        .then((data: any) => {
                            Emitter.emit("alert", {
                                type: "success",
                                message:
                                    "Position has been successfully updated.",
                                description: "",
                                top: true,
                                closeable: false,
                                timeout: 3000,
                            });
                            setSideContainerOpen(false);
                            resetForm(values);
                        })
                        .catch((e: any) => {
                            //console.log("error", e);
                            Emitter.emit("alert", {
                                type: "error",
                                message:
                                    e.response.data.detail +
                                    ". Please try again.",
                                description: "",
                                top: true,
                                closeable: false,
                                timeout: 3000,
                            });
                        })
                        .finally(() => {
                            Emitter.emit("finish-loading", null);
                            setRefreshData(!refreshData);
                            context?.handleFormUpdating(false);
                            socket.emit("pong-update", () => {
                                console.log("Pinging server");
                            });
                        });
                    break;
                case "leavetype":
                    values.status = "active";
                    updateLeaveTypeItem([activeRowKey], values)
                        .then((data: any) => {
                            Emitter.emit("alert", {
                                type: "success",
                                message:
                                    "Leave type has been successfully updated.",
                                description: "",
                                top: true,
                                closeable: false,
                                timeout: 3000,
                            });
                            if (employeeData) {
                                for (const employee of employeeData) {
                                    employee.leave_entitled.map(
                                        (leavetype: any) => {
                                            if (
                                                leavetype.leave_type ===
                                                values.leave_type_code
                                            ) {
                                                let leave_entitled;
                                                if (
                                                    leavetype.leave_type ===
                                                    "annual"
                                                ) {
                                                    leave_entitled =
                                                        employee.num_leaves;
                                                } else {
                                                    leave_entitled = Number(
                                                        values.default_days
                                                    );
                                                }
                                                leavetype.entitled =
                                                    leave_entitled;
                                            }
                                            return leavetype;
                                        }
                                    );
                                    // console.log(
                                    //     "Test employee",
                                    //     employee._id,
                                    //     employee
                                    // );
                                    updateEmployeeItem([employee._id], employee)
                                        .then((data: any) => {
                                            console.log(
                                                "employee data updated suceesfully"
                                            );
                                        })
                                        .catch((error: any) => {
                                            console.log("error", error);
                                        });
                                }
                            } else {
                                // console.log("Test employee", employeeData);
                            }
                            setSideContainerOpen(false);
                        })
                        .catch((e: any) => {
                            //console.log("error", e);
                            Emitter.emit("alert", {
                                type: "error",
                                message:
                                    e.response.data.detail +
                                    ". Please try again.",
                                description: "",
                                top: true,
                                closeable: false,
                                timeout: 3000,
                            });
                        })
                        .finally(() => {
                            Emitter.emit("finish-loading", null);
                            setRefreshData(!refreshData);
                            context?.handleFormUpdating(false);
                            socket.emit("pong-update", () => {
                                console.log("Pinging server");
                            });
                        });
                    break;
                case "holiday":
                    values.ph_date = moment(values.ph_date.toDate()).format(
                        "YYYY-MM-DD"
                    );
                    updateHolidayItem([activeRowKey], values)
                        .then((data: any) => {
                            Emitter.emit("alert", {
                                type: "success",
                                message:
                                    "Holiday has been successfully updated.",
                                description: "",
                                top: true,
                                closeable: false,
                                timeout: 3000,
                            });
                            values.ph_date = dayjs(values.ph_date);
                            setSideContainerOpen(false);
                            resetForm(values);
                        })
                        .catch((e: any) => {
                            //console.log("error", e);
                            values.ph_date = dayjs(values.ph_date);
                            Emitter.emit("alert", {
                                type: "error",
                                message:
                                    e.response.data.detail +
                                    ". Please try again.",
                                description: "",
                                top: true,
                                closeable: false,
                                timeout: 3000,
                            });
                        })
                        .finally(() => {
                            Emitter.emit("finish-loading", null);
                            setRefreshData(!refreshData);
                            context?.handleFormUpdating(false);
                            socket.emit("pong-update", () => {
                                console.log("Pinging server");
                            });
                        });
                    break;
                case "workday":
                    updateWorkdayItem([activeRowKey], values)
                        .then((data: any) => {
                            Emitter.emit("alert", {
                                type: "success",
                                message:
                                    "Workday has been successfully updated.",
                                description: "",
                                top: true,
                                closeable: false,
                                itmeout: 3000,
                            });
                            setSideContainerOpen(false);
                            resetForm(values);
                        })
                        .catch((e: any) => {
                            //console.log("error", e);
                            Emitter.emit("alert", {
                                type: "error",
                                message:
                                    e.response.data.detail +
                                    ". Please try again.",
                                description: "",
                                top: true,
                                closeable: false,
                                timeout: 3000,
                            });
                        })
                        .finally(() => {
                            Emitter.emit("finish-loading", null);
                            setRefreshData(!refreshData);
                            context?.handleFormUpdating(false);
                            socket.emit("pong-update", () => {
                                console.log("Pinging server");
                            });
                        });
                    break;
                case "policy":
                    if (values.uuid) {
                        // console.log("uuid", values.uuid);
                        let policyList: any = {};
                        const filename = values.uuid.fileList
                            ? values.uuid.fileList[0].name
                            : values.filename;

                        const fileUUID =
                            imgNameUUIDList.find(
                                (item: any) => item.name === filename
                            )?.uuid || values.uuid;
                        values.last_updated = moment(
                            values.last_updated.toDate()
                        ).format("YYYY-MM-DD");
                        policyList = {
                            ...values,
                            filename: filename,
                            uuid: fileUUID,
                        };
                        // console.log(policyList, values.uuid);
                        if (fileUUID !== prevUUID) {
                            policyList.deletePolicy = prevUUID;
                        }
                        updatePolicyItem([activeRowKey], policyList)
                            .then((data: any) => {
                                updatePolicyFileItem(
                                    [activeRowKey],
                                    policyList
                                ).then((policyData: any) => {
                                    Emitter.emit("alert", {
                                        type: "success",
                                        message:
                                            "Policy has been successfully updated.",
                                        description: "",
                                        top: true,
                                        closeable: false,
                                        timeout: 3000,
                                    });
                                    values.last_updated = dayjs(
                                        values.last_updated
                                    );
                                    setSideContainerOpen(false);
                                    setImgNameUUIDList([]);
                                    resetForm(values);
                                });
                            })
                            .catch((e: any) => {
                                values.last_updated = dayjs(
                                    values.last_updated
                                );
                                //console.log("error", e);
                                Emitter.emit("alert", {
                                    type: "error",
                                    message:
                                        e.response.data.detail +
                                        ". Please try again.",
                                    description: "",
                                    top: true,
                                    closeable: false,
                                    timeout: 3000,
                                });
                            })
                            .finally(() => {
                                Emitter.emit("finish-loading", null);
                                setRefreshData(!refreshData);
                                context?.handleFormUpdating(false);
                                socket.emit("pong-update", () => {
                                    console.log("Pinging server");
                                });
                            });
                    }

                    break;
            }
        }
    };

    // Handle form delete
    const handleFormDelete = (activeRowKey: any) => {
        let values = formValues || formRef.getFieldsValue();
        context?.handleFormUpdating(false);
        Emitter.emit("loading", null);
        switch (subTabKey) {
            case "position":
                deletePositionItem([activeRowKey])
                    .then(() => {
                        Emitter.emit("alert", {
                            type: "success",
                            message: "Position has been successfully removed.",
                            description: "",
                            top: true,
                            closeable: false,
                            timeout: 3000,
                        });
                        resetForm();
                    })
                    .catch(() => {
                        Emitter.emit("alert", {
                            type: "error",
                            message:
                                "Failed to delete position. Please try again.",
                            description: "",
                            top: true,
                            closeable: false,
                            timeout: 3000,
                        });
                    })
                    .finally(() => {
                        // setActiveRowKey(null);
                        Emitter.emit("finish-loading", null);
                        setRefreshData(!refreshData);
                        setSideContainerOpen(false);
                        context?.handleFormUpdating(false);
                        socket.emit("pong-update", () => {
                            console.log("Pinging server");
                        });
                    });
                break;
            case "leavetype":
                deleteLeaveTypeItem([activeRowKey])
                    .then(() => {
                        Emitter.emit("alert", {
                            type: "success",
                            message:
                                "Leave type has been successfully removed.",
                            description: "",
                            top: true,
                            closeable: false,
                            timeout: 3000,
                        });
                        if (employeeData) {
                            for (const employee of employeeData) {
                                employee.leave_entitled =
                                    employee.leave_entitled.filter(
                                        (leavetype: any) => {
                                            return (
                                                leavetype.leave_type !==
                                                values.leave_type_code
                                            );
                                        }
                                    );
                                // console.log(
                                //     "Test employee",
                                //     employee._id,
                                //     employee
                                // );
                                updateEmployeeItem([employee._id], employee)
                                    .then((data: any) => {
                                        console.log(
                                            "employee data updated suceesfully"
                                        );
                                    })
                                    .catch((error: any) => {
                                        console.log("error", error);
                                    });
                            }
                        }
                        resetForm();
                    })
                    .catch(() => {
                        Emitter.emit("alert", {
                            type: "error",
                            message:
                                "Failed to delete leave type. Please try again.",
                            description: "",
                            top: true,
                            closeable: false,
                            timeout: 3000,
                        });
                    })
                    .finally(() => {
                        // setActiveRowKey(null);
                        Emitter.emit("finish-loading", null);
                        setRefreshData(!refreshData);
                        setSideContainerOpen(false);
                        context?.handleFormUpdating(false);
                        socket.emit("pong-update", () => {
                            console.log("Pinging server");
                        });
                    });
                break;
            case "holiday":
                deleteHolidayItem([activeRowKey])
                    .then(() => {
                        Emitter.emit("alert", {
                            type: "success",
                            message: "Holiday has been successfully removed.",
                            description: "",
                            top: true,
                            closeable: false,
                            timeout: 3000,
                        });
                        resetForm();
                    })
                    .catch(() => {
                        Emitter.emit("alert", {
                            type: "error",
                            message:
                                "Failed to delete holiday. Please try again.",
                            description: "",
                            top: true,
                            closeable: false,
                            timeout: 3000,
                        });
                    })
                    .finally(() => {
                        // setActiveRowKey(null);
                        Emitter.emit("finish-loading", null);
                        setRefreshData(!refreshData);
                        setSideContainerOpen(false);
                        context?.handleFormUpdating(false);
                        socket.emit("pong-update", () => {
                            console.log("Pinging server");
                        });
                    });
                break;
            case "policy":
                deletePolicyItem([activeRowKey])
                    .then(() => {
                        deleteFile(data.uuid, "policy_files").then(() => {
                            Emitter.emit("alert", {
                                type: "success",
                                message:
                                    "Policy has been successfully removed.",
                                description: "",
                                top: true,
                                closeable: false,
                                timeout: 3000,
                            });
                            resetForm();
                        });
                    })
                    .catch(() => {
                        Emitter.emit("alert", {
                            type: "error",
                            message:
                                "Failed to delete policy. Please try again.",
                            description: "",
                            top: true,
                            closeable: false,
                            timeout: 3000,
                        });
                    })
                    .finally(() => {
                        // setActiveRowKey(null);
                        Emitter.emit("finish-loading", null);
                        setRefreshData(!refreshData);
                        setSideContainerOpen(false);
                        context?.handleFormUpdating(false);
                        socket.emit("pong-update", () => {
                            console.log("Pinging server");
                        });
                    });
                break;
        }
    };

    // Handle form Cancel
    const handleFormCancel = () => {
        context?.handleFormUpdating(false);
        resetForm(initialForm);
    };

    // Function to capitalize the first letter of leaves
    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const filterOption = (input: any, option: any) => {
        return option.label.toLowerCase().includes(input.toLowerCase());
    };

    const handleTemporaryOptions = (caseName: any) => {
        const getMappedArray = (caseName: any) => {
            switch (caseName) {
                case "workperiod":
                    return [
                        {
                            key: "offday",
                            label: "Off day",
                            value: "offday",
                        },
                        {
                            key: "fullday",
                            label: "Full day",
                            value: "fullday",
                        },
                    ];
                default:
                    return [];
            }
        };
        return getMappedArray(caseName);
    };

    const setInputType = (element: any, index: any) => {
        let dataObject: any = data;
        switch (element.type) {
            case "input":
                let disabledState = false;
                if (activeRowKey && element.disabledRequired) {
                    disabledState = true;
                }
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                    >
                        <Input
                            disabled={disabledState}
                            autoFocus={index == 0 && !activeRowKey}
                            autoComplete="off"
                            onChange={(event: any) => {
                                const value = event.target.value
                                    .replace(/^ /, "")
                                    .replace(/\s+/g, " ");
                                formRef.setFieldValue(element.name, value);

                                setFormReset(false);
                            }}
                            style={{
                                width: element.width
                                    ? `${element.width * 4}%`
                                    : "100%",
                                borderColor: element.borderColor || "#000000",
                            }}
                        ></Input>
                    </Form.Item>
                );
            case "textArea":
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                    >
                        <TextArea
                            autoFocus={index == 0 && !activeRowKey}
                            onChange={() => {
                                setFormReset(false);
                            }}
                        ></TextArea>
                    </Form.Item>
                );
            case "grid":
                return (
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: `repeat(${element.children.length}, 1fr)`,
                            gap: `${element.gap}`,
                        }}
                    >
                        {element.children.map((child: any, index: any) =>
                            setInputType(child, index)
                        )}
                    </div>
                );
            case "gridx":
                return (
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: element.gridLength
                                ? element.gridLength
                                      .map((length: any) => `${length}fr`)
                                      .join(" ")
                                : `repeat(${element.children.length}, 1fr)`,
                            gap: `${element.gap}`,
                        }}
                    >
                        {element.children.map((child: any, index: any) =>
                            setInputType(child, index)
                        )}
                    </div>
                );
            case "disabled":
                return (
                    <Form.Item
                        required={element.required}
                        label={element.label}
                        name={element.name}
                    >
                        <Input
                            // style={{ color: "#000000" }}
                            disabled
                            placeholder={capitalizeFirstLetter(
                                get(dataObject, element.name, "")
                            )}
                        ></Input>
                    </Form.Item>
                );
            case "checkbox":
                return (
                    <Form.Item label={element.label} name={element.name}>
                        <Checkbox
                            checked={checkboxValue}
                            onChange={(event: any) => {
                                const value = event.target.checked;
                                setCheckboxValue(value);
                                formRef.setFieldValue(element.name, value);
                                handleFormChange();
                            }}
                            // placeholder={get(dataObject, element.name)}
                        >
                            Yes
                        </Checkbox>
                    </Form.Item>
                );
            case "disabledDescription":
                if (data.reason) {
                    return (
                        <Form.Item
                            required={false}
                            label={element.label}
                            name={element.name}
                        >
                            <Input
                                // style={{ color: "#000000" }}
                                disabled
                                placeholder={capitalizeFirstLetter(
                                    get(dataObject, element.name, "")
                                )}
                            ></Input>
                        </Form.Item>
                    );
                } else {
                    return null;
                }
            case "disabledRemarks":
                if (data.half_days) {
                    return (
                        <Form.Item
                            required={false}
                            label={element.label}
                            name={element.name}
                        >
                            <TextArea
                                disabled
                                placeholder={get(dataObject, element.name)}
                            ></TextArea>
                        </Form.Item>
                    );
                } else {
                    return null;
                }
            case "disabledDate":
                return (
                    <Form.Item
                        required={false}
                        label={element.label}
                        name={element.name}
                    >
                        <Input
                            disabled
                            placeholder={moment(
                                get(dataObject, element.name, "").split(" ")[0]
                            ).format("DD MMM YYYY")}
                        ></Input>
                    </Form.Item>
                );
            case "datePicker":
                let fetchedDateString = formRef.getFieldValue(element.name);
                let formattedDate = dayjs(
                    fetchedDateString,
                    "DD MMM YYYY HH:mm:ss"
                );
                const customFormat: DatePickerProps["format"] = (value: any) =>
                    `${value.format("DD MMM YYYY")}`;
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                    >
                        <DatePicker
                            style={{
                                width: element.width
                                    ? `${element.width * 4}%`
                                    : "100%",
                                borderColor: element.borderColor || "#000000",
                                backgroundColor: "rgba(255,255,255,0.1)",
                            }}
                            autoFocus={index == 0 && !activeRowKey}
                            onChange={(date, x) => {
                                setFormReset(false);
                                // console.log(date);
                            }}
                            value={formattedDate || undefined}
                            format={customFormat}
                        ></DatePicker>
                    </Form.Item>
                );
            case "divider":
                return (
                    <Divider
                        style={{ margin: 0, color: "rgba(255,255,255,0.45)" }}
                        key={element.label}
                        orientation="left"
                    >
                        {element.label}
                    </Divider>
                );
            case "select-temporary":
                // dataObject = filteredExtraData || dataTable;
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                    >
                        <Select
                            defaultValue={element.default}
                            placeholder={get(data, element.name, "")}
                            autoFocus={index == 0 && !activeRowKey}
                            allowClear
                            filterOption={filterOption}
                            showSearch
                            options={handleTemporaryOptions(element.case)}
                            mode={element.mode || undefined}
                            onChange={() => {
                                // setForceModalRenderer(forceModalRenderer + 1);
                                setFormReset(false);
                            }}
                            style={{
                                width: element.width
                                    ? `${element.width * 4}%`
                                    : "100%",
                                borderColor: element.borderColor || "#000000",
                            }}
                        ></Select>
                    </Form.Item>
                );
            case "attachment_download":
                return (
                    <>
                        {data.uuid && (
                            <Form.Item
                                required={false}
                                label={element.label}
                                name={element.name}
                            >
                                <div key={index}>
                                    <Button
                                        style={{
                                            textWrap: "wrap",
                                            textAlign: "start",
                                        }}
                                        type="link"
                                        onClick={() =>
                                            downloadFile(
                                                data.filename,
                                                data.uuid,
                                                "policy_files"
                                            )
                                        }
                                    >
                                        {data.filename}
                                    </Button>
                                </div>
                            </Form.Item>
                        )}
                        <Form.Item
                            label={"New File"}
                            name={element.name}
                            rules={[{ required: true, message: "" }]}
                        >
                            <Upload.Dragger
                                maxCount={1}
                                name="file"
                                listType="picture"
                                fileList={filelist}
                                customRequest={async (info: any) => {
                                    const formData = new FormData();
                                    formData.append("file", info.file);
                                    const filename = info.file.name;

                                    const imgUUID =
                                        uuidv4() +
                                        "." +
                                        filename.split(".").pop();
                                    formData.append("uuid", imgUUID);
                                    // setImgNameUUIDList([
                                    //     ...imgNameUUIDList,
                                    //     { name: filename, uuid: imgUUID },
                                    // ]);
                                    // formData.append("folder_name", "policy");

                                    uploadFile(formData)
                                        .then((data: any) => {
                                            setImgNameUUIDList([
                                                {
                                                    name: filename,
                                                    uuid: imgUUID,
                                                },
                                            ]);
                                            info.onSuccess(data, info.file);
                                        })
                                        .catch((error: any) => {
                                            info.onError(error, info.file);
                                        });
                                }}
                                accept=".pdf"
                                headers={{
                                    authorization: "authorization-text",
                                }}
                                onChange={(info: any) => {
                                    setFilelist(info.fileList);
                                }}
                                style={{
                                    width: element.width
                                        ? `${element.width * 4}%`
                                        : "100%",
                                    borderColor:
                                        element.borderColor || "#000000",
                                    textWrap: "wrap",
                                }}
                            >
                                <Button icon={<UploadOutlined />}>
                                    {isMobile ? <></> : <>Click to upload</>}
                                </Button>
                            </Upload.Dragger>
                        </Form.Item>
                        <p style={{ fontSize: "14px" }}>
                            Only one document (in PDF format) is allowed to be
                            uploaded for each policy.
                        </p>
                    </>
                );
            // }

            case "empty":
                return <div key={index}></div>;
            default:
                return <Input disabled></Input>;
        }
    };

    const setForm = () => {
        const formList = getFormOptionList(tabKey, subTabKey);
        if (formList) {
            return (
                <Form
                    layout="vertical"
                    form={formRef}
                    onChange={handleFormChange}
                    onFinish={(values: any) => {
                        // console.log("Send value", values);
                    }}
                    requiredMark={false}
                    name="administration_form"
                    style={{
                        gap: "10px",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    {formList.map((item: any, index: number) => {
                        return setInputType(item, index);
                    })}
                </Form>
            );
        }
    };

    return (
        <div
            className="lookup-side-container"
            style={{
                width: isMobile ? "100%" : "35%",
                maxHeight: "100%",
                margin: "10px",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                borderRadius: "3px",
            }}
        >
            <div className="lookup-side-header" style={{ height: "50px" }}>
                <span
                    style={{
                        marginRight: "auto",
                        minHeight: "32px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    {/* {tabKey.charAt(0).toUpperCase() +
                                tabKey.split("_").join(" ").slice(1)}{" "} */}
                    {capitalizeFirstLetter(subTabKey)} details
                </span>
                <button
                    onClick={() => {
                        setSideContainerOpen(false);
                        setActiveRowKey(null);
                        // setActiveGroupKey(null);
                    }}
                    style={{
                        color: "rgba(255, 255, 255, 1)",
                        fontSize: "15px",
                        // fontWeight: "600",
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                    }}
                >
                    X
                </button>
            </div>
            <div
                className="generic-content"
                style={{
                    flex: "1",
                    gap: "0px",
                    maxHeight: "100%",
                    overflow: "auto",
                    flexWrap: "nowrap",
                    backgroundColor: "#ffffff",
                    border: "solid 1px",
                    borderLeftColor: "#A7A7A7",
                    borderRightColor: "#A7A7A7",
                }}
            >
                {setForm()}
            </div>
            <div className="generic-footer" style={{ height: "50px" }}>
                {activeRowKey && subTabKey !== "workday" && (
                    <Popconfirm
                        style={{
                            marginRight: "auto",
                            background: "#393939",
                        }}
                        overlayInnerStyle={{
                            maxWidth: "300px",
                            padding: "16px",
                        }}
                        placement="topLeft"
                        okText="Delete"
                        okType="danger"
                        title=""
                        icon={
                            <ExclamationCircleOutlined
                                style={{ color: "orange" }}
                            />
                        }
                        cancelText={<span>Cancel</span>}
                        overlayClassName="popconfirm-danger"
                        description="Permanently delete this item? This action cannot be undone."
                        onOpenChange={(open: any) => {
                            if (!open) {
                                setEmptyModalOpen(false);
                            }
                        }}
                        onConfirm={() => {
                            setEmptyModalOpen(false);
                            handleFormDelete(activeRowKey);
                        }}
                        onCancel={() => {
                            setEmptyModalOpen(false);
                        }}
                    >
                        <Button
                            className="ant-btn-secondary"
                            style={{ marginRight: "auto" }}
                            danger
                        >
                            Delete
                        </Button>
                    </Popconfirm>
                )}
                <FormButtonCancel
                    form={formRef}
                    handleFormCancel={handleFormCancel}
                    formReset={formReset}
                ></FormButtonCancel>
                <div>
                    <FormButtonSave
                        form={formRef}
                        handleFormSave={handleFormSave}
                        formReset={formReset}
                    ></FormButtonSave>
                </div>
            </div>
        </div>
    );
};

export default AdministrationCustomForm;
