import { useEffect, useRef } from "react";
import { useForm } from "antd/es/form/Form";
import { Form, Input, Divider, Button, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { getFormOptionList } from "../utils/lookup_list";
import { downloadFile } from "../services/api-server/filetransfer";
import { get } from "lodash";
import { daysCalculation } from "../utils/utils";
import moment from "moment";
const { Text } = Typography;

const LeaveApprovalForm = (props: any) => {
    const data = props.data || [];

    const initialForm = useRef(null);
    const [formRef] = useForm();
    // const formRef = props.formRef;
    const activeRowKey = props?.activeRowKey;
    const setFormReset = props?.setFormReset;
    const tabKey = props?.tabKey;
    const subTabKey = props?.subTabKey;
    const holidayData = props?.holidayData;
    const workdayData = props?.workdayData;

    const handleApproval = (values: any) => {
        if (values) {
            //console.log("Approved application. ", values);
        } else {
            //console.log("Rejected application. ", values);
        }
    };

    const handleFormDelete = (values: any) => {
        //console.log("delete items", values);
    };

    // Function to capitalize the first letter of leaves
    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const setInputType = (element: any, index: any) => {
        let dataObject: any = data;
        // console.log("data object", dataObject);
        switch (element.type) {
            case "input":
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                    >
                        <Input
                            autoFocus={index == 0 && !activeRowKey}
                            autoComplete="off"
                            onChange={(event: any) => {
                                const value = event.target.value
                                    .replace(/^ /, "")
                                    .replace(/\s+/g, " ");
                                formRef.setFieldValue(element.name, value);

                                setFormReset(false);
                            }}
                            style={{
                                width: element.width
                                    ? `${element.width * 4}%`
                                    : "100%",
                                borderColor: element.borderColor || "#000000",
                            }}
                        ></Input>
                    </Form.Item>
                );
            case "textArea":
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                    >
                        <TextArea
                            autoFocus={index == 0 && !activeRowKey}
                            onChange={() => {
                                setFormReset(false);
                            }}
                        ></TextArea>
                    </Form.Item>
                );
            case "grid":
                return (
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: `repeat(${element.children.length}, 1fr)`,
                            gap: `${element.gap}`,
                        }}
                    >
                        {element.children.map((child: any, index: any) =>
                            setInputType(child, index)
                        )}
                    </div>
                );
            case "gridx":
                return (
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: element.gridLength
                                ? element.gridLength
                                      .map((length: any) => `${length}fr`)
                                      .join(" ")
                                : `repeat(${element.children.length}, 1fr)`,
                            gap: `${element.gap}`,
                        }}
                    >
                        {element.children.map((child: any, index: any) =>
                            setInputType(child, index)
                        )}
                    </div>
                );
            case "disabled":
                return (
                    <Form.Item
                        required={false}
                        label={element.label}
                        name={element.name}
                    >
                        <Input
                            // style={{ color: "#000000" }}
                            disabled
                            placeholder={capitalizeFirstLetter(
                                get(dataObject, element.name, "")
                            )}
                        ></Input>
                    </Form.Item>
                );
            case "disabledDescription":
                if (data.reason) {
                    return (
                        <Form.Item
                            required={false}
                            label={element.label}
                            name={element.name}
                        >
                            <TextArea
                                disabled
                                placeholder={capitalizeFirstLetter(
                                    get(dataObject, element.name)
                                )}
                            ></TextArea>
                        </Form.Item>
                    );
                } else {
                    return null;
                }

            case "disabledRemarks":
                if (data.half_days) {
                    return (
                        <Form.Item
                            required={false}
                            label={element.label}
                            name={element.name}
                        >
                            <TextArea
                                disabled
                                placeholder={get(dataObject, element.name)}
                            ></TextArea>
                        </Form.Item>
                    );
                } else {
                    return null;
                }
            case "disabledDate":
                return (
                    <Form.Item
                        required={false}
                        label={element.label}
                        name={element.name}
                    >
                        <Input
                            disabled
                            placeholder={moment(
                                get(dataObject, element.name, "").split(" ")[0]
                            ).format("DD MMM YYYY")}
                        ></Input>
                    </Form.Item>
                );
            case "divider":
                return (
                    <Divider
                        style={{ margin: 0, color: "rgba(255,255,255,0.45)" }}
                        key={element.label}
                        orientation="left"
                    >
                        {element.label}
                    </Divider>
                );
            case "attachment_download":
                // console.log(data.attachment);
                if (data.attachment) {
                    return (
                        <Form.Item
                            required={false}
                            label={element.label}
                            name={element.name}
                        >
                            {data.attachment.map((item: any, index: any) => {
                                return (
                                    <div key={index}>
                                        {/* <a href={`${item.uuid}`} download>
                                            {item.name}
                                        </a> */}
                                        <Button
                                            type="link"
                                            onClick={() =>
                                                downloadFile(
                                                    item.name,
                                                    item.uuid,
                                                    "leave_attachments"
                                                )
                                            }
                                        >
                                            {item.name}
                                        </Button>
                                    </div>
                                );
                            })}
                        </Form.Item>
                    );
                } else {
                    return null;
                }

            case "empty":
                return <div key={index}></div>;
            default:
                return <Input disabled></Input>;
        }
    };

    useEffect(() => {
        // console.log(data);
        formRef.setFieldsValue({
            days: daysCalculation(
                workdayData,
                holidayData,
                data.start_date,
                data.end_date,
                data.half_days
            ),
        });
    }, [data]);

    const setForm = () => {
        // console.log(props)
        // console.log(tabKey)
        const formList = getFormOptionList(tabKey, subTabKey);
        //console.log(formList);
        if (formList) {
            return (
                <Form
                    layout="vertical"
                    form={formRef}
                    requiredMark={false}
                    name="leave_approval_form"
                    style={{
                        gap: "10px",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    {formList.map((item: any, index: number) => {
                        return setInputType(item, index);
                    })}

                    {/* Action buttons */}
                    {/* <div className="generic-footer" style={{ height: "50px" }}>
								
						<Button
							className="ant-btn-secondary"
							style={{ marginRight: "auto" }}
							danger
						>
							Delete
						</Button>
							 
							
					</div> */}
                </Form>
            );
        }
    };

    return (
        <div style={{ height: "95%" }}>
            <div style={{ textAlign: "right" }}>
                <span style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                    #{data.leave_id_num}
                </span>
            </div>

            {setForm()}
        </div>
    );
};

export default LeaveApprovalForm;
