import axios from "axios";
import { AuthHeader, serverConnection } from "../../utils/_exports";

const apiURL = "user_acc";

// User Register route
export const registerUserAcc = (data: any) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${serverConnection}/${apiURL}/`, data, AuthHeader)
            .then((_data: any) => {
                if (_data?.data.data.data) {
                    resolve(_data.data.data.data);
                } else {
                    resolve(null);
                }
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

// User Login route
// export const loginUserAcc = (data: any) => {
//     return new Promise((resolve, reject) => {
//         axios
//             .post(`${serverConnection}/${apiURL}/login`, data, {
//                 withCredentials: true,
//             })
//             .then((_data: any) => {
//              //console.log(_data);
//                 if (_data.data) {
//                     resolve(_data.data);
//                 } else {
//                     resolve(null);
//                 }
//             })
//             .catch((e: any) => {
//                 reject(e);
//             });
//     });
// };

// get All user account info [email, password, role]
export const getAllUsers = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${serverConnection}/${apiURL}/`, AuthHeader)
            .then((_data: any) => {
                if (_data?.data.data.data && _data.data.data.data.length) {
                    resolve(_data.data.data.data);
                } else {
                    resolve(null);
                }
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

// export const updateUserItem = (_id: any, data: any, prevData: any) => {
// export const updateUserItem = (_ids: Array<any>, data: any) => {
//     const _data = {
//         query: { _id: { $in: _ids } },
//         data: data,
//     };
//     return new Promise((resolve, reject) => {
//         axios
//             // .put(`${url}/${apiURL}/`, _data, AuthHeader)
//             .put(`${serverConnection}/${apiURL}/`, _data, AuthHeader)
//             .then((_data: any) => {
//                 resolve(_data.data.data.data);
//             })
//             .catch((e: any) => {
//                 reject(e);
//             });
//     });
// };
export const updateUserItem = (employee_id: any, data: any) => {
    const _data = {
        query: { employee_id: employee_id },
        data: data,
    };
    return new Promise((resolve, reject) => {
        axios
            // .put(`${url}/${apiURL}/`, _data, AuthHeader)
            .put(`${serverConnection}/${apiURL}/`, _data, AuthHeader)
            .then((_data: any) => {
                resolve(_data.data.data.data);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

// delete user account

export const deleteUserItem = (_id: any) => {
    return new Promise((resolve, reject) => {
        axios
            // .delete(`${url}/${apiURL}/${_id}`, {
            .delete(`${serverConnection}/${apiURL}/${_id}`, {
                params: {
                    _id: _id,
                },
                ...AuthHeader,
            })
            .then((_data) => {
                resolve(true);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};
