/**
 * Terra's Login Page with Microsoft sign in
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import swinburneFullLogo from "../assets/image/swinburne logo.png";
import rajangLogo from "../assets/image/rajang logo.png";
//import poweredByLogo from "../assets/image/dragonfly.svg";
import "../assets/css/loginPage.css";
import {
    Layout,
    Button,
    Input,
    ConfigProvider,
    theme,
    Space,
    Form,
    Image,
    Divider,
    Alert,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import microsoftLogo from "../assets/image/Microsoft_logo.svg";
// import IluvatarLogo from "../assets/image/Iluvatar_loginlogo.png";
import { GetAntIcon, GetFileType } from "../utils/ant_icons";
// import { loginUserAcc } from "../services/api-server/user_acc";
import Emitter from "../utils/emitter";
import { getAlertStyle } from "../utils/utils";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { isMobile } from "react-device-detect";

const { Content } = Layout;

const poweredByLogo: string = "/api/__core/get_rails_logo";

const LoginPage = (props: any) => {
    const navigate = useNavigate();
    const [alertmsg, setAlertmsg] = useState<any>(null);
    const userRole = props?.userRole;
    // When form is finished (Temp unavailable)

    useEffect(() => {
        if (
            localStorage.getItem(`idToken`) &&
            localStorage.getItem(`idToken`) !== undefined
        ) {
            navigate(`/leave`, { replace: true });
        }
    });

    useEffect(() => {
        if (localStorage.getItem(`LoginMessage`)) {
            // Emitter.emit("alert", {
            //     type: "error",
            //     title: "Error",
            //     message: localStorage.getItem(`LoginMessage`),
            //     description: "",
            //     top: true,
            //     closeable: false,
            //     timeout: 3000,
            // });
            setAlertmsg({
                type: "warning",
                message: localStorage.getItem(`LoginMessage`),
                top: true,
                closeable: false,
            });
            setInterval(() => {
                localStorage.removeItem(`LoginMessage`);
                setAlertmsg(null);
            }, 3000);
        }
    }, []);

    // const onFinish = (values: any) => {
    //     loginUserAcc(values)
    //         .then((res: any) => {
    //          //console.log("Success:", res);
    //             window.location.href = "/loginsuccess";
    //             // navigate(`/dashboard`, { replace: true });
    //             // window.location.href = res;
    //         })
    //         .catch((err: any) => {
    //          //console.log("Failed:", err);
    //             setAlertmsg({
    //                 type: "warning",
    //                 message:
    //                     "Username or password incorrect. Please try again.",
    //                 top: true,
    //                 closeable: true,
    //             });

    //             // alert("Username or password is incorrect. Please try again.")
    //             // Emitter.emit("alert", {
    //             //     type: "error",
    //             //     title: "Error",
    //             //     message:
    //             //         "Username or password is incorrect. Please try again.",
    //             //     description: "",
    //             //     top: true,
    //             //     closeable: false,
    //             //     timeout: 3000,
    //             // });
    //         });
    // };

    // Login Via Microsoft EntraID
    const MicrosoftSignIn = () => {
        window.location.href = `${process.env.REACT_APP_SERVER_URL}/login`;
        // axios
        //     .get(`${process.env.REACT_APP_SERVER_URL}/login`)
        //     .catch((err: any) => {
        //         Emitter.emit("alert", {
        //             type: "error",
        //             title: "Error",
        //             message: err,
        //             description: "",
        //             top: true,
        //             closeable: false,
        //             timeout: 3000,
        //         });
        //     });
    };

    return (
        <Layout className="login-body">
            <Content>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                    }}
                    className="container"
                >
                    {!isMobile && (
                        <div className="left-container">
                        <div className="container-center">
                            <div className="display-container">
                                {/* <Image
									preview={false}
									style={{
										position: "fixed",
										left: "48px",
										bottom: "70px",
										width: "285px",
										height: "90px",
									}}
									src={IluvatarLogo}
								></Image> */}
                            </div>
                        </div>
                    </div>
                    )}
                   
                    <div className="right-container" style={{minWidth:isMobile?"100%":"450px"}}>
                        <Space
                            className="login-container"
                            direction="vertical"
                            size={50}
                        >
                            <Space
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                className="login-header-container"
                                direction="vertical"
                            >
                                <Image
                                    src={rajangLogo}
                                    preview={false}
                                    width={180}
                                ></Image>
                            </Space>
                            <Form
                                name="login_form"
                                className="login-form"
                                // onFinish={onFinish}
                            >
                                {/* <div style={{ marginBottom: "15px" }}>
                                    <Form.Item name="email">
                                        <Input
                                            // className="login-input"
                                            required
                                            prefix={
                                                <span className="login-icon">
                                                    {GetAntIcon("user")}
                                                </span>
                                            }
                                            placeholder="email"
                                            style={{
                                                backgroundColor: "#ffffff",
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item name="password">
                                        <Input
                                            // className="login-input"
                                            required
                                            prefix={
                                                <span className="login-icon">
                                                    {GetAntIcon("lock")}
                                                </span>
                                            }
                                            type="password"
                                            placeholder="password"
                                            style={{
                                                backgroundColor: "#ffffff",
                                            }}
                                        />
                                    </Form.Item>
                                </div>

                                <Form.Item style={{ marginBottom: "0px" }}>
                                    {" "}
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        // onClick={MicrosoftSignIn}
                                        className="login-form-button normal-login-button"
                                    >
                                        Sign in via Email
                                    </Button>
                                </Form.Item>
                                <a
                                    style={{
                                        float: "right",
                                        color: "#FFFFFF75",

                                        fontSize: "10px",
                                        lineHeight: "22px",
                                        fontWeight: "400",
                                    }}
                                >
                                    Forgot password
                                </a>
                                <Divider
                                    style={{
                                        fontSize: "14px",
                                        color: "#FFFFFF73",
                                    }}
                                >
                                    Or
                                </Divider>
                                <Form.Item style={{ marginBottom: "0px" }}>
                                    {" "}
                                    <Link to="/registration">
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            // onClick={MicrosoftSignIn}
                                            className="login-form-button normal-login-button"
                                        >
                                            No account? Click to register
                                        </Button>
                                    </Link>
                                </Form.Item>
                                <Divider
                                    style={{
                                        fontSize: "14px",
                                        color: "#FFFFFF73",
                                    }}
                                ></Divider> */}
                                <Form.Item>
                                    <Link to="/">
                                        <Button
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                            icon={
                                                <>
                                                    {" "}
                                                    <Image
                                                        preview={false}
                                                        height="20px"
                                                        width="20px"
                                                        src={microsoftLogo}
                                                    />
                                                </>
                                            }
                                            type="primary"
                                            onClick={MicrosoftSignIn}
                                            className="microsoft-login-button login-form-button"
                                        >
                                            Sign in with Microsoft
                                        </Button>
                                    </Link>
                                </Form.Item>
                                <span
                                    style={{
                                        fontSize: "10px",
                                        lineHeight: "22px",
                                        fontWeight: "400",
                                        color: "#FFFFFF75",
                                    }}
                                >
                                    Having problems?{" "}
                                    <a style={{ color: "#ffffff" }}>
                                        Contact us
                                    </a>
                                </span>
                            </Form>
                        </Space>
                        <div className="login-container-footer">
                            <span
                                style={{ fontSize: "14px", lineHeight: "22px" }}
                            >
                                Powered by
                            </span>
                            <Image
                                preview={false}
                                height="60px"
                                width="60px"
                                src={poweredByLogo}
                            />
                        </div>
                    </div>
                    {alertmsg && (
                        <Alert
                            className={
                                alertmsg?.top
                                    ? "alert-message-box-top"
                                    : "alert-message-box"
                            }
                            type={alertmsg?.type}
                            message={alertmsg?.message}
                            description={alertmsg?.description}
                            showIcon
                            closable={alertmsg?.closeable}
                            afterClose={() => setAlertmsg(null)}
                            style={{
                                fontFamily: "Open Sans,sans-serif, arial",
                                ...getAlertStyle(alertmsg?.type),
                            }}
                        />
                    )}
                </div>
            </Content>
        </Layout>
    );
};

// LoginPage.propTypes = {};

export default LoginPage;
