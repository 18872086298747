import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Menu, Layout, Image, Button, Drawer } from "antd";
import {
    MenuOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraAddOutlined,
} from "@ant-design/icons";
import rajangPng from "../assets/image/rajang logo.png";
import swinburneFullLogoSvg from "../assets/image/swinburne logo.svg";
import { GetAntIcon } from "../utils/ant_icons";
import { logout } from "../utils/utils";
import { superAdminRole } from "../utils/_exports";
import { useNavigate } from "react-router";
import Emitter from "../utils/emitter";
import { MenuItem } from "@mui/material";
import { HomeContext } from "../containers/Home";
import { isMobile } from "react-device-detect";

const { Sider } = Layout;

// Change container key to find the proper component. You will need to go to utils/containers.tsx to add new components into it
export const initialMainMenu: any = [
    {
        key: "dashboard-menu",
        label: "Dashboard and Reports",
        propTitle: "Dashboard",
        container: "tabContainer",
        icon: GetAntIcon("global"),
        to: "/dashboard",
        requires_admin: true,
    },
    // {
    // 	key: "payroll-menu",
    // 	label: "Payroll",
    // 	propTitle: "Payroll",
    // 	container: "default",
    // 	icon: GetAntIcon("dollar"),
    // 	to: "/default",
    // },
    {
        key: "leave-menu",
        label: "Leave Application",
        propTitle: "Leave Application",
        container: "leaveContainer",
        icon: GetAntIcon("calendar"),
        to: "/leave",
    },
    // {
    // 	key: "claims-menu",
    // 	label: "Claims",
    // 	propTitle: "Claims",
    // 	container: "default",
    // 	icon: GetAntIcon("file"),
    // 	to: "/default",
    // },
    // {
    // 	key: "recruitment-menu",
    // 	label: "Recruitment",
    // 	propTitle: "Recruitment",
    // 	container: "default",
    // 	icon: GetAntIcon("user-add"),
    // 	to: "/default",
    // },
    // {
    // 	key: "learning-menu",
    // 	label: "Learning",
    // 	propTitle: "Learning",
    // 	container: "default",
    // 	icon: GetAntIcon("bulb"),
    // 	to: "/default",
    // },
    // {
    // 	key: "performance-menu",
    // 	label: "Performance",
    // 	propTitle: "Performance",
    // 	container: "default",
    // 	icon: GetAntIcon("line"),
    // 	to: "/default",
    // },
    // {
    // 	key: "talent-menu",
    // 	label: "Talent",
    // 	propTitle: "Talent",
    // 	container: "default",
    // 	icon: GetAntIcon("star"),
    // 	to: "/default",
    // },
    // {
    // 	key: "succession-menu",
    // 	label: "Succession",
    // 	propTitle: "Succession",
    // 	container: "default",
    // 	icon: GetAntIcon("deploymentunit"),
    // 	to: "/default",
    // },
    // {
    // 	key: "employee-menu",
    // 	label: "Employee self-service",
    // 	propTitle: "Employees",
    // 	container: "default",
    // 	icon: GetAntIcon("folder-outline"),
    // 	to: "/default",
    // },
];

export const initialExtraMenu: any = [
    // {
    //     key: "help-menu",
    //     label: "Help center",
    //     propTitle: "Help center",
    //     className: "extra-menu-item",
    //     icon: GetAntIcon("question3"),
    // },
    {
        key: "policies-menu",
        label: "Company Policies",
        propTitle: "Company Policies",
        className: "extra-menu-item",
        container: "policiesContainer",
        to: "/policies",
        icon: GetAntIcon("file"),
    },
    {
        key: "admin-menu",
        label: "Administration",
        propTitle: "Administration",
        className: "extra-menu-item",
        container: "administrationContainer",
        to: "/administration",
        icon: GetAntIcon("setting"),
        requires_admin: true,
    },
    {
        key: "profile-menu",
        label: "Profile",
        propTitle: "Profile",
        icon: GetAntIcon("user"),
        className: "extra-menu-item",
        children: [
            // {
            // 	key: "user-profile",
            // 	label: "My profile",
            // 	propTitle: "My profile",
            // 	container: "profile",
            // 	to: "/profile",
            // },
            {
                key: "manage-account",
                label: "Manage account",
                propTitle: "Manage account",
            },
            { key: "some-divider", type: "divider" },
            { key: "logout", label: "Sign out", propTitle: "Sign out" },
        ],
    },
];

const MainMenu = (props: any) => {
    const context: any = useContext(HomeContext);
    const navigate = useNavigate();
    const [selectedKeys, setSelectedKeys] = useState<any>([]);
    const [drawerVisible, setDrawerVisible] = useState(false);

    const renderMenu = () => {
        const createMainMenu = (roles: any) => {
            return initialMainMenu.map((menuItem: any) => {
                if (menuItem.requires_admin == true) {
                    if (roles?.includes(superAdminRole)) {
                        return menuItem;
                    } else {
                        return null;
                    }
                } else {
                    return menuItem;
                }
            });
        };

        const handleMainMenu = async (info: any) => {
            try {
                await context.handlePageChange();
                if (info.item.props.to) {
                    setSelectedKeys([info.key]);
                    Emitter.emit("menuChange", [info.item.props]);
                    navigate(info.item.props.to);
                }
            } catch {}
        };
        return (
            <div style={{ height: "50%", overflowY: "auto" }}>
                <Menu
                    className="main-menu"
                    mode="inline"
                    defaultSelectedKeys={["dashboard-menu"]}
                    selectedKeys={props.selectedKey || selectedKeys}
                    items={createMainMenu(props.roles)}
                    onClick={(info: any) => {
                        setDrawerVisible(false);
                        handleMainMenu(info);
                    }}
                ></Menu>
            </div>
        );
    };

    const renderExtraMenu = () => {
        const createExtraMenu = (roles: any) => {
            return initialExtraMenu.map((menuItem: any) => {
                if (menuItem.requires_admin == true) {
                    if (roles?.includes(superAdminRole)) {
                        return menuItem;
                    } else {
                        return null;
                    }
                } else {
                    return menuItem;
                }
            });
        };
        const handleExtraMenu = async (info: any) => {
            try {
                await context.handlePageChange();
                if (info.item.props.to) {
                    Emitter.emit("menuChange", [info.item.props]);
                    setSelectedKeys([info.key]);
                    if (info.item.props.requires_admin) {
                        // navigate("/admin" + info.item.props.to);
                        navigate(info.item.props.to);
                    } else {
                        navigate(info.item.props.to);
                    }
                }
                if (info.key === "logout") {
                    logout();
                }
                if (info.key === "manage-account") {
                    window.open(
                        "https://myaccount.microsoft.com/?ref=MeControl",
                        "_blank"
                    );
                }
            } catch {}
        };

        return (
            <Menu
                selectedKeys={props.selectedKey || selectedKeys}
                className="extra-menu"
                style={{ marginTop: "auto" }}
                mode="inline"
                items={createExtraMenu(props.roles)}
                onClick={(info: any) => {
                    setDrawerVisible(false);
                    handleExtraMenu(info);
                }}
            ></Menu>
        );
    };

    const handleShowDrawer = () => {
        setDrawerVisible(true);
    };

    const handleCloseDrawer = () => {
        setDrawerVisible(false);
    };

    return (
        <>
            {props.option != "Mega" ? (
                isMobile ? (
                    <>
                        <Button
                            type="primary"
                            onClick={handleShowDrawer}
                            style={{
                                position: "fixed",
                                top: "10px",
                                left: "10px",
                                zIndex: 100,
                            }}
                        >
                            <MenuOutlined />
                        </Button>
                        <Drawer
                            placement="left"
                            closable={false}
                            onClose={handleCloseDrawer}
                            open={drawerVisible}
                            width={300}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "20px",
                                    marginBottom: "15px",
                                }}
                            >
                                <Image
                                    preview={false}
                                    width={"75px"}
                                    className="home-logo"
                                    src={rajangPng}
                                    alt="Logo"
                                />
                            </div>
                            {renderMenu()}
                            {renderExtraMenu()}
                        </Drawer>
                    </>
                ) : (
                    <Sider
                        className="main-sider"
                        collapsed={props.collapsed}
                        onCollapse={props.onCollapse}
                        collapsible
                        width={props.collapsed ? "80px" : "230px"}
                        style={{
                            // borderRight: "0.5px solid #343642",
                            boxShadow: "2px 0px 5px rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "20px",
                                marginBottom: "15px",
                            }}
                        >
                            {props.collapsed ? (
                                <Image
                                    preview={false}
                                    width={"35px"}
                                    className="home-logo"
                                    src={rajangPng}
                                    alt="Logo"
                                />
                            ) : (
                                <Image
                                    preview={false}
                                    width={"75px"}
                                    className="home-logo"
                                    src={rajangPng}
                                    alt="Logo"
                                />
                            )}
                        </div>
                        {renderMenu()}
                        {renderExtraMenu()}
                    </Sider>
                )
            ) : (
                <></>
            )}
        </>
    );
};

MainMenu.propTypes = {
    collapsed: PropTypes.bool,
    onCollapse: PropTypes.func,
    option: PropTypes.any,
    roles: PropTypes.array,
    selectedKey: PropTypes.any,
};

export default MainMenu;
