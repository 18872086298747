import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Form,
    Input,
    Select,
    Tag,
    Divider,
    ColorPicker,
    DatePicker,
    DatePickerProps,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { getFormOptionList } from "../utils/lookup_list";
import { GetAntIcon } from "../utils/ant_icons";
import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { position } from "html2canvas/dist/types/css/property-descriptors/position";
import { CollectionsBookmarkRounded, OtherHouses } from "@mui/icons-material";
import { getEmployeeByID } from "../services/api-server/employee";
import { active } from "d3";
dayjs.extend(customParseFormat);

const CustomForm = (props: any) => {
    const [initialForm, setInitialForm]: any = useState<any>(null);
    const [selectedRole, setSelectedRole] = useState<any>(null);
    const [selectFieldValues, setSelectFieldValues] = useState<any>({});
    const [employeeData, setEmployeeData] = useState<any>([]);

    const [filteredExtraData, setFilteredExtraData] = useState<any>(null);

    // Hooks for End Date
    // const [endDateVisibility, setEndDateVisibility] = useState(false);
    const endDateVisibility = props.endDateVisibility;
    const setEndDateVisibility = props.setEndDateVisibility;

    const formRef = props.formRef;
    const dataTable = props.dataTable; // Data from the table which is previously named as extraData
    const activeRowKey = props?.activeRowKey;
    const handleFormCancel = props?.handleFormCancel;
    const handleFormSave = props?.handleFormSave;
    const handleFormDelete = props?.handleFormDelete;
    const getDeleteMessage = props?.getDeleteMessage;
    const resetForm = props?.resetForm;
    const setFormReset = props?.setFormReset;
    const tabKey = props?.tabKey;
    const positionData = props?.positionData;
    const employeeType = props.employeeType;
    // Watch all values
    // const formValues: any = Form.useWatch([], formRef);

    useEffect(() => {
        if (employeeType == "intern") {
            formRef.setFieldValue("position", "INTERN");
        }
    }, [endDateVisibility]);

    useEffect(() => {
        const employee_data = dataTable.employees?.find(
            (item: any) => item._id === activeRowKey
        );
        setEmployeeData(employeeData);
        if (employee_data?.status === "inactive" && employee_data?.date_end) {
            formRef.setFieldValue("date_end", dayjs(employee_data.date_end));
            setEndDateVisibility(true);
        }
    }, [activeRowKey]);

    const filterOption = (input: any, option: any) => {
        return option.label.toLowerCase().includes(input.toLowerCase());
    };

    const handleFormChange = () => {
        setFormReset(false);
        const values = formRef.getFieldsValue();
        //console.log("Custom form values", values);
    };

    const onStatusChange: any = (e: any) => {
        // console.log(e);
        // setFormReset(false);
        if (e === "inactive") {
            setEndDateVisibility(true);
            formRef.setFieldValue("date_end", undefined);
            // formRef.setFieldValue("status", "inactive");
            handleFormChange();
        } else {
            setEndDateVisibility(false);
            // formRef.setFieldValue("status", "active");
            formRef.setFieldValue("date_end", undefined);
            // setFormReset(false);
            handleFormChange();
        }
    };

    const handleSelectOptions = (
        keyProperty: any,
        keyLabel: any,
        data: any = null,
        relay: any = null
    ) => {
        let mappedArray: any = [];
        const uniqueValues = new Set();

        // set Dropdown selection list
        if (keyProperty && keyLabel) {
            let dataArray = data || dataTable;
            // If it requires a relay but the relay value has no data, return empty
            if (
                (relay && formRef.getFieldValue(relay) == undefined) ||
                formRef.getFieldValue(relay) == ""
            ) {
                mappedArray = [];
            } else {
                mappedArray = dataArray
                    .map((obj: any) => {
                        const label = obj[keyLabel];
                        const key = obj[keyProperty];

                        // Check if the value is unique
                        if (!uniqueValues.has(key) && label != "" && key) {
                            uniqueValues.add(key);
                            return { label: label, value: key };
                        }

                        return null; // If not unique, return null
                    })
                    .filter(Boolean) // Remove null values
                    .sort((a: any, b: any) => {
                        if (a.label.toLowerCase() < b.label.toLowerCase()) {
                            return -1;
                        }
                        if (a.label.toLowerCase() > b.label.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    });
            }
        }

        return mappedArray;
    };

    const handleTemporaryOptions = (caseName: any) => {
        let mappedArray: any = [];
        switch (caseName) {
            case "nationality":
                mappedArray = [
                    { key: "malaysia", label: "Malaysian", value: "Malaysian" },
                    { key: "america", label: "American", value: "American" },
                    { key: "china", label: "Chinese", value: "Chinese" },
                    { key: "india", label: "Indian", value: "Indian" },
                    {
                        key: "indonesia",
                        label: "Indonesian",
                        value: "Indonesian",
                    },
                    { key: "britain", label: "British", value: "British" },
                    {
                        key: "philippines",
                        label: "Filipino",
                        value: "Filipino",
                    },
                ];
                break;
            case "race":
                mappedArray = [
                    { key: "malay", label: "Malay", value: "Malay" },
                    { key: "chinese", label: "Chinese", value: "Chinese" },
                    { key: "indian", label: "Indian", value: "Indian" },
                    { key: "iban", label: "Iban", value: "Iban" },
                    {
                        key: "kadazan-dusun",
                        label: "Kadazan-Dusun",
                        value: "Kadazan-Dusun",
                    },
                    { key: "bidayuh", label: "Bidayuh", value: "Bidayuh" },
                    { key: "melanau", label: "Melanau", value: "Melanau" },
                    {
                        key: "orang-asli",
                        label: "Orang Asli",
                        value: "Orang Asli",
                    },
                    { key: "others", label: "Others", value: "Others" },
                ];
                break;
            case "religion":
                mappedArray = [
                    {
                        key: "christianity",
                        label: "Christianity",
                        value: "Christianity",
                    },
                    { key: "islam", label: "Islam", value: "Islam" },
                    { key: "hinduism", label: "Hinduism", value: "Hinduism" },
                    { key: "buddhism", label: "Buddhism", value: "Buddhism" },
                    { key: "others", label: "Others", value: "Others" },
                ];
                break;
            case "roles":
                mappedArray = [
                    { key: "user", label: "User", value: "user" },
                    { key: "admin", label: "Admin", value: "admin" },
                ];
                break;
            case "position":
                if (employeeType === "employee") {
                    mappedArray = positionData
                        ?.filter((item: any) => item.position_name !== "Intern")
                        ?.map((filtered_data: any) => {
                            return {
                                key: filtered_data.position_code,
                                label: filtered_data.position_name,
                                value: filtered_data.position_code,
                            };
                        })
                        ?.sort((a: any, b: any) =>
                            a.label > b.label ? 1 : b.label > a.label ? -1 : 0
                        );
                } else if (employeeType === "intern") {
                    mappedArray = [
                        { key: "INTERN", label: "Intern", value: "INTERN" },
                    ];
                } else {
                    mappedArray = positionData?.map((filtered_data: any) => {
                        return {
                            key: filtered_data.position_code,
                            label: filtered_data.position_name,
                            value: filtered_data.position_code,
                        };
                    });
                }
                break;
            case "employeeStatus":
                mappedArray = [
                    { key: "active", label: "Active", value: "active" },
                    { key: "inactive", label: "Inactive", value: "inactive" },
                ];
                break;
            default:
                mappedArray = [];
                break;
        }
        return mappedArray;
    };

    // Set the form field type based on the type from the form list
    const setInputType = (element: any, index: any) => {
        let dataObject: any = dataTable;
        // console.log("data object", dataObject);
        switch (element.type) {
            case "input":
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                    >
                        <Input
                            autoFocus={index == 0 && !activeRowKey}
                            autoComplete="off"
                            onChange={(event: any) => {
                                const value = event.target.value
                                    .replace(/^ /, "")
                                    .replace(/\s+/g, " ");
                                formRef.setFieldValue(element.name, value);

                                setFormReset(false);
                            }}
                            style={{
                                width: element.width
                                    ? `${element.width * 4}%`
                                    : "100%",
                                borderColor: element.borderColor || "#000000",
                            }}
                        ></Input>
                    </Form.Item>
                );
            case "textArea":
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                    >
                        <TextArea
                            autoFocus={index == 0 && !activeRowKey}
                            onChange={() => {
                                setFormReset(false);
                            }}
                        ></TextArea>
                    </Form.Item>
                );
            case "select":
                dataObject = filteredExtraData || dataTable;
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                    >
                        <Select
                            autoFocus={index == 0 && !activeRowKey}
                            allowClear
                            filterOption={filterOption}
                            showSearch
                            options={handleSelectOptions(
                                element.optionKey,
                                element.optionLabel,
                                element.extraKey
                                    ? dataObject[element.extraKey]
                                    : null,
                                element.relayFrom
                            )}
                            mode={element.mode || undefined}
                            tagRender={(props: any) => {
                                if (element.mode === "tags") {
                                    let tag = dataTable?.find(
                                        (element: any) => {
                                            return props.label == element.name;
                                        }
                                    );
                                    if (tag) {
                                        return (
                                            <Tag color={tag.color}>
                                                {tag.name}
                                            </Tag>
                                        );
                                    }
                                }
                                return <></>;
                            }}
                            onChange={(value: any) => {
                                setFormReset(false);
                                //console.log("value", value);
                            }}
                        ></Select>
                    </Form.Item>
                );
            case "colorPicker":
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                        initialValue={"#299BA3"}
                    >
                        <ColorPicker
                            showText
                            // defaultValue="#000000"
                            onChange={() => {
                                setFormReset(false);
                            }}
                        ></ColorPicker>
                    </Form.Item>
                );
            case "grid":
                return (
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: `repeat(${element.children.length}, 1fr)`,
                            gap: `${element.gap}`,
                        }}
                    >
                        {element.children.map((child: any, index: any) =>
                            setInputType(child, index)
                        )}
                    </div>
                );
            case "gridx":
                return (
                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: element.gridLength
                                ? element.gridLength
                                      .map((length: any) => `${length}fr`)
                                      .join(" ")
                                : `repeat(${element.children.length}, 1fr)`,
                            gap: `${element.gap}`,
                        }}
                    >
                        {element.children.map((child: any, index: any) =>
                            setInputType(child, index)
                        )}
                    </div>
                );

            case "gridy":
                return (
                    <div
                        style={{
                            display: "grid",
                            gridTemplateRows: element.gridLength
                                ? element.gridLength
                                      .map((length: any) => `${length}fr`)
                                      .join(" ")
                                : `repeat(${element.children.length}, 1fr)`,
                            gap: `${element.gap}`,
                        }}
                    >
                        {element.children.map((child: any, index: any) =>
                            setInputType(child, index)
                        )}
                    </div>
                );
            case "disabled":
                return (
                    <Form.Item
                        required={false}
                        label={element.label}
                        name={element.name}
                    >
                        <Input disabled></Input>
                    </Form.Item>
                );
            case "datePicker":
                if (endDateVisibility || element.name === "date_join") {
                    let fetchedDateString = formRef.getFieldValue(element.name);
                    // console.log("test", element.name, fetchedDateString);
                    // let formattedDate = fetchedDateString;
                    let formattedDate = dayjs(
                        fetchedDateString,
                        "DD MMM YYYY HH:mm:ss"
                    );
                    const customFormat: DatePickerProps["format"] = (
                        value: any
                    ) => `${value.format("DD MMM YYYY")}`;
                    // if (!formattedDate.isValid()) {
                    //     formattedDate = fetchedDateString;
                    // }
                    return (
                        <Form.Item
                            label={element.label}
                            name={element.name}
                            rules={[
                                { required: element.required, message: "" },
                            ]}
                        >
                            <DatePicker
                                style={{
                                    width: element.width
                                        ? `${element.width * 4}%`
                                        : "100%",
                                    borderColor:
                                        element.borderColor || "#000000",
                                    backgroundColor: "rgba(255,255,255,0.1)",
                                }}
                                autoFocus={index == 0 && !activeRowKey}
                                onChange={(date, x) => {
                                    setFormReset(false);
                                    // console.log(date);
                                }}
                                value={formattedDate || undefined}
                                format={customFormat}
                            ></DatePicker>
                        </Form.Item>
                    );
                } else {
                    return null;
                }

            case "divider":
                return (
                    <Divider
                        style={{
                            margin: 0,
                            marginTop: "20px",
                            color: "rgba(255,255,255,0.45)",
                        }}
                        key={element.label}
                        orientation="left"
                    >
                        {/* add color to label */}
                        <span style={{ color: "rgba(0,0,0, 0.5)" }}>
                            {element.label}
                        </span>
                    </Divider>
                );
            case "select-multiple":
                let fieldValues = formRef.getFieldValue(element.name) || [];
                return (
                    <div
                        style={{
                            gap: "10px",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                gap: "8px",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Form.Item
                                style={{ display: "none" }}
                                name={element.name}
                                rules={[
                                    { required: element.required, message: "" },
                                ]}
                            ></Form.Item>
                            <span>{element.label}</span>
                            <Select
                                autoFocus={index == 0 && !activeRowKey}
                                filterOption={filterOption}
                                showSearch
                                options={handleSelectOptions(
                                    element.optionKey,
                                    element.optionLabel,
                                    element.extraKey
                                        ? dataObject[element.extraKey]
                                        : null,
                                    element.relayFrom
                                )}
                                value={null}
                                autoClearSearchValue
                                mode={element.mode || undefined}
                                tagRender={(props: any) => {
                                    if (element.mode === "tags") {
                                        let tag = dataTable?.find(
                                            (element: any) => {
                                                return (
                                                    props.label == element.name
                                                );
                                            }
                                        );
                                        if (tag) {
                                            return (
                                                <Tag color={tag.color}>
                                                    {tag.name}
                                                </Tag>
                                            );
                                        }
                                    }
                                    return <></>;
                                }}
                                onChange={(value: any) => {
                                    let formValues =
                                        formRef.getFieldValue(element.name) ||
                                        [];
                                    formValues = [...formValues];

                                    formValues.push(value);
                                    formRef.setFieldValue(
                                        element.name,
                                        formValues
                                    );

                                    let dataState: any = [];
                                    let data: any = {};

                                    if (filteredExtraData) {
                                        dataState = [
                                            ...filteredExtraData[
                                                element.extraKey
                                            ],
                                        ];
                                        data = { ...filteredExtraData };
                                    } else {
                                        dataState = [
                                            ...dataTable[element.extraKey],
                                        ];
                                        data = { ...dataTable };
                                    }
                                    let filteredData = dataState.filter(
                                        (item: any) =>
                                            item[element.optionKey] != value
                                    );
                                    data[element.extraKey] = [...filteredData];
                                    setFilteredExtraData(data);
                                }}
                            ></Select>
                        </div>
                        {dataTable[element.extraKey]
                            ?.filter((item: any) => {
                                return fieldValues?.includes(
                                    item[element.optionKey]
                                );
                            })
                            .map((item: any) => {
                                return (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                            alignSelf: "stretch",
                                        }}
                                    >
                                        <Input
                                            style={{
                                                color: "rgba(255,255,255,0.85)",
                                                flex: 1,
                                            }}
                                            disabled
                                            value={formRef.getFieldValue(
                                                "role"
                                            )}
                                        />
                                        <Input
                                            style={{
                                                color: "rgba(255,255,255,0.85)",
                                                flex: 1,
                                            }}
                                            disabled
                                            value={item[element.optionLabel]}
                                        />
                                        <span
                                            className="clickable-text"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                let formValues =
                                                    formRef.getFieldValue(
                                                        element.name
                                                    ) || [];
                                                formValues = [...formValues];
                                                let dataState: any = [];
                                                let data: any = {};

                                                // 	if (filteredExtraData) {
                                                // 		dataState = [...filteredExtraData[element.extraKey]];
                                                // 		data = { ...filteredExtraData };
                                                // 	} else {
                                                // 		dataState = [...extraData[element.extraKey]];
                                                // 		data = { ...extraData };
                                                // 	}

                                                // 	let filteredData = dataState;
                                                // 	let foundData = extraData[element.extraKey].find(
                                                // 		(_element: any) =>
                                                // 			_element[element.optionKey] ===
                                                // 			item[element.optionKey]
                                                // 	);
                                                // 	let foundIndex = formValues.findIndex(
                                                // 		(_element: any) =>
                                                // 			_element === item[element.optionKey]
                                                // 	);
                                                // 	if (foundIndex != -1 && foundData) {
                                                // 		formValues.splice(foundIndex, 1);
                                                // 		filteredData.push(foundData);
                                                // 		filteredData = filteredData.sort((a: any, b: any) =>
                                                // 			a[element.optionLabel].localeCompare(
                                                // 				b[element.optionLabel]
                                                // 			)
                                                // 		);
                                                // 		formRef.setFieldValue(element.name, formValues);
                                                // 		data[element.extraKey] = [...filteredData];
                                                // 	}
                                                // 	setFilteredExtraData(data);
                                                // 	setFormReset(false);
                                            }}
                                        >
                                            {GetAntIcon("close")}
                                        </span>
                                    </div>
                                );
                            })}
                    </div>
                );
            case "role-dynamic-form":
                const formChanged = () => {
                    let formData = formRef.getFieldValue(element.name);
                    let filteredData = dataTable[element.extraKey].filter(
                        (data: any) =>
                            data["name"].toLowerCase() !== "project manager" &&
                            !formData.some(
                                (item: any) => item["role"] === data["name"]
                            )
                    );

                    let data = { ...dataTable };
                    data[element.extraKey] = [...filteredData];
                    setFilteredExtraData(data);
                    setFormReset(false);
                };
                dataObject = filteredExtraData || dataTable;
                return (
                    <div
                        style={{
                            gap: "10px",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {/* Create a form item that will use to select options as its value */}
                        <div
                            style={{
                                gap: "8px",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: `repeat(${element.children.length}, 1fr)`,
                                    gap: `${element.gap}`,
                                }}
                            >
                                <Form.List name={element.name}>
                                    {(fields, { add, remove }) => (
                                        <>
                                            {element.children.map(
                                                (child: any, index: any) => (
                                                    <Form.Item
                                                        label={child.label}
                                                        rules={[
                                                            {
                                                                required:
                                                                    child.required,
                                                                message: "",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            onDropdownVisibleChange={() =>
                                                                formChanged()
                                                            }
                                                            autoFocus={
                                                                index == 0 &&
                                                                !activeRowKey
                                                            }
                                                            showSearch
                                                            disabled={
                                                                element.children
                                                                    .length -
                                                                    1 ===
                                                                    index &&
                                                                !selectedRole
                                                            }
                                                            options={handleSelectOptions(
                                                                child.optionKey,
                                                                child.optionLabel,
                                                                child.extraKey
                                                                    ? dataObject[
                                                                          child
                                                                              .extraKey
                                                                      ]
                                                                    : null,
                                                                child.relayFrom
                                                            )}
                                                            autoClearSearchValue
                                                            tagRender={(
                                                                props: any
                                                            ) => {
                                                                if (
                                                                    child.mode ===
                                                                    "tags"
                                                                ) {
                                                                    let tag =
                                                                        dataTable?.find(
                                                                            (
                                                                                child: any
                                                                            ) => {
                                                                                return (
                                                                                    props.label ==
                                                                                    child.name
                                                                                );
                                                                            }
                                                                        );
                                                                    if (tag) {
                                                                        return (
                                                                            <Tag
                                                                                color={
                                                                                    tag.color
                                                                                }
                                                                            >
                                                                                {
                                                                                    tag.name
                                                                                }
                                                                            </Tag>
                                                                        );
                                                                    }
                                                                }
                                                                return <></>;
                                                            }}
                                                            value={
                                                                element.children
                                                                    .length -
                                                                    1 ===
                                                                index
                                                                    ? null
                                                                    : selectedRole
                                                            }
                                                            onChange={(
                                                                value: any,
                                                                option: any
                                                            ) => {
                                                                if (
                                                                    element
                                                                        .children
                                                                        .length -
                                                                        1 !==
                                                                    index
                                                                ) {
                                                                    // option.label is assigned to store the label of the option which is the role name
                                                                    let formValues =
                                                                        selectFieldValues;
                                                                    formValues[
                                                                        child.name
                                                                    ] =
                                                                        option.label;
                                                                    setSelectFieldValues(
                                                                        formValues
                                                                    );
                                                                    setSelectedRole(
                                                                        option.label
                                                                    );
                                                                    // console.log("form values", formValues);
                                                                } else {
                                                                    // value is pushed to store the email of the appointee
                                                                    let formValues =
                                                                        selectFieldValues;
                                                                    formValues[
                                                                        child.name
                                                                    ] = value;
                                                                    setSelectFieldValues(
                                                                        formValues
                                                                    );
                                                                    setSelectedRole(
                                                                        null
                                                                    );
                                                                }

                                                                // The dynamic form will be added only if the all the fields are filled
                                                                if (
                                                                    element
                                                                        .children
                                                                        .length ===
                                                                    Object.keys(
                                                                        selectFieldValues
                                                                    ).length
                                                                ) {
                                                                    add(
                                                                        selectFieldValues
                                                                    );
                                                                    setSelectFieldValues(
                                                                        {}
                                                                    );
                                                                }
                                                            }}
                                                        ></Select>
                                                    </Form.Item>
                                                )
                                            )}
                                            {fields.map(
                                                ({
                                                    key,
                                                    name,
                                                    ...restField
                                                }) => (
                                                    <>
                                                        {element.children.map(
                                                            (
                                                                child: any,
                                                                index: any
                                                            ) =>
                                                                element.children
                                                                    .length -
                                                                    1 !==
                                                                index ? (
                                                                    <>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[
                                                                                name,
                                                                                child.name,
                                                                            ]}
                                                                            rules={[
                                                                                {
                                                                                    required:
                                                                                        true,
                                                                                    message:
                                                                                        "",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                style={{
                                                                                    color: "rgba(255,255,255,0.85)",
                                                                                    flex: 1,
                                                                                }}
                                                                                disabled
                                                                            />
                                                                        </Form.Item>
                                                                    </>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            alignContent:
                                                                                "center",
                                                                            alignItems:
                                                                                "center",
                                                                            gap: "10px",
                                                                        }}
                                                                    >
                                                                        <Form.Item
                                                                            {...restField}
                                                                            name={[
                                                                                name,
                                                                                child.name,
                                                                            ]}
                                                                            rules={[
                                                                                {
                                                                                    required:
                                                                                        true,
                                                                                    message:
                                                                                        "",
                                                                                },
                                                                            ]}
                                                                            style={{
                                                                                flex: 1,
                                                                            }}
                                                                        >
                                                                            <Select
                                                                                mode={
                                                                                    child.mode ||
                                                                                    undefined
                                                                                }
                                                                                filterOption={
                                                                                    filterOption
                                                                                }
                                                                                showSearch
                                                                                options={handleSelectOptions(
                                                                                    child.optionKey,
                                                                                    child.optionLabel,
                                                                                    child.extraKey
                                                                                        ? dataObject[
                                                                                              child
                                                                                                  .extraKey
                                                                                          ]
                                                                                        : null,
                                                                                    child.relayFrom
                                                                                )}
                                                                            ></Select>
                                                                        </Form.Item>
                                                                        {/* Used Form.Item to use the same css settings as other form items. */}
                                                                        <Form.Item>
                                                                            {/* Close Button */}
                                                                            <span
                                                                                className="clickable-text"
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                    margin: "auto",
                                                                                    padding:
                                                                                        "2px",
                                                                                }}
                                                                                onClick={() => {
                                                                                    remove(
                                                                                        name
                                                                                    );
                                                                                    formChanged();
                                                                                }}
                                                                            >
                                                                                {GetAntIcon(
                                                                                    "close"
                                                                                )}
                                                                            </span>
                                                                        </Form.Item>
                                                                    </div>
                                                                )
                                                        )}
                                                    </>
                                                )
                                            )}
                                        </>
                                    )}
                                </Form.List>
                            </div>
                        </div>
                    </div>
                );
            case "select-temporary":
                dataObject = filteredExtraData || dataTable;
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                    >
                        <Select
                            autoFocus={index == 0 && !activeRowKey}
                            allowClear
                            filterOption={filterOption}
                            showSearch
                            options={handleTemporaryOptions(element.case)}
                            mode={element.mode || undefined}
                            onChange={(value: any) => {
                                setFormReset(false);
                                // console.log("value", value);
                            }}
                            style={{
                                borderColor: element.borderColor || "#000000",
                            }}
                        ></Select>
                    </Form.Item>
                );
            case "select-status":
                dataObject = filteredExtraData || dataTable;
                return (
                    <Form.Item
                        label={element.label}
                        name={element.name}
                        rules={[{ required: element.required, message: "" }]}
                    >
                        <Select
                            autoFocus={index == 0 && !activeRowKey}
                            allowClear
                            filterOption={filterOption}
                            showSearch
                            options={handleTemporaryOptions(element.case)}
                            mode={element.mode || undefined}
                            onChange={onStatusChange}
                            style={{
                                borderColor: element.borderColor || "#000000",
                            }}
                        ></Select>
                    </Form.Item>
                );
            default:
                return <Input disabled></Input>;
        }
    };

    // Populate form with data from the form list
    const setForm = () => {
        const formList = getFormOptionList(tabKey, employeeType);
        const values = formRef.getFieldsValue();
        // console.log("formref data d-debuggg", dataTable);
        // const employee_data = dataTable.employees?.find(
        //     (item: any) => item._id === activeRowKey
        // );
        // if (employee_data?.date_end) {
        //     setEndDateVisibility(true);
        // }
        if (employeeType === "intern") {
            formRef.setFieldsValue({
                position: "INTERN",
                // status: "active",
            });
        }
        if (formList) {
            return (
                <Form
                    layout={"vertical"}
                    form={formRef}
                    requiredMark="optional"
                    style={{
                        gap: "20px",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    }}
                    onChange={handleFormChange}
                    onFinish={(values: any) => {
                        //console.log("Send value", values);
                    }}
                >
                    {formList.map((element: any, index: any) =>
                        setInputType(element, index)
                    )}
                </Form>
            );
        } else {
            return null;
        }
    };

    return setForm();
};

export default CustomForm;
