import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { serverConnection, url } from "../utils/_exports";
import {
    Button,
    Space,
    Table,
    Tabs,
    Tag,
    Dropdown,
    Modal,
    Form,
    Input,
    DatePicker,
    Upload,
    message,
    Divider,
    Select,
} from "antd";

import TextArea from "antd/es/input/TextArea";

import type { ColumnsType } from "antd/es/table";
import {
    getAddButtonProp,
    getColumnList,
    getLookupItem,
    getFormOptionList,
} from "../utils/lookup_list";
import Emitter from "../utils/emitter";
import { HomeContext } from "./Home";
import { useNavigate } from "react-router";
import { ConsoleSqlOutlined, UploadOutlined } from "@ant-design/icons";
import { updateLeaveItem, addLeaveItem } from "../services/api-server/leave";
import { uploadFile } from "../services/api-server/filetransfer";
import { updateEmployeeItem } from "../services/api-server/employee";
import { daysCalculation } from "../utils/utils";
import { set, update } from "lodash";
import Item from "antd/es/list/Item";
import { v4 as uuidv4 } from "uuid";
import { calc } from "antd/es/theme/internal";
// import { LeaveConfiguration } from "./LeaveConfiguration";

import LeaveApprovalForm from "../components/LeaveApprovalForm";
import LeaveApplication from "./LeaveApplication";
import { color } from "d3";
import { OtherHouses } from "@mui/icons-material";
import moment from "moment";
import { sendEmail } from "../services/api-server/usertoken";
import AdministrationCustomForm from "../components/AdministrationCustomForm";
import { isMobile } from "react-device-detect";

const LookupConfiguration = (props: any) => {
    const context: any = useContext(HomeContext);
    const navigate: any = useNavigate();

    const role = props?.role;
    const userData = props?.userData;
    const [tabKey, setTabKey] = useState<any>(props.tabKey);
    const [subTabKey, setSubTabKey] = useState<any>(props.subTabKey || null);
    const containerTitle = props?.containerTitle;
    const [tableColumns, setTableColumns] = useState<ColumnsType<any>>([]);
    const [dataTable, setDataTable] = useState<any[]>([]);
    const [dbData, setDbData] = useState<any>({});

    const [filteredDataTable, setFilteredDataTable] = useState<any>(null);
    const [sideContainerOpen, setSideContainerOpen] = useState<any>(
        props.sideContainerOpen || false
    );
    const [activeRowKey, setActiveRowKey] = useState<any>(
        props.activeRowKey || null
    );
    const [activeRecord, setActiveRecord] = useState<any>(
        props.activeRecord || null
    );
    const [expandedRowKeys, setExpandRowKey] = useState<any>([]);
    const [holidayData, setHolidayData] = useState<any>(
        props.holidayData || null
    );
    const [workdayData, setWorkdayData] = useState<any>(
        props.workdayData || null
    );
    const [policyData, setPolicyData] = useState<any>(props.policyData || null);
    const [positionData, setPositionData] = useState<any>([]);
    const [addButtonProp, setAddButtonProp] = useState<any>(null);
    const [searchRecord, setSearchRecord] = useState<any>([]);
    const [tempRecord, setTempRecord] = useState<any>(null);

    const [tableHeight, setTableHeight] = useState<any>(null);
    const [ready, setReady] = useState<any>(false);

    const [activeGroupKey, setActiveGroupKey] = useState<any>(null);

    const [showRejectionModal, setShowRejectionModal] = useState<any>(false);
    const [rejectionModalStatus, setRejectionModalStatus] = useState<any>("");
    const [rejectionReason, setRejectionReason] = useState<any>("");

    const [showLeaveApplication, setShowLeaveApplication] =
        useState<any>(false);
    const [leaveform] = Form.useForm();
    const [refreshData, setRefreshData] = useState<any>(false);

    const [imgNameUUIDList, setImgNameUUIDList] = useState<any>([]);

    const currentDate = new Date();

    useEffect(() => {
        // positionData
        getLookupItem("position")
            .then((data: any) => {
                setPositionData(data);
            })
            .catch((error: any) => console.log(error));
    }, [tempRecord]);

    useEffect(() => {
        // console.log("tempRecord activated: ", tempRecord);
        if (searchRecord && tempRecord) {
            // console.log("11111", searchRecord, tempRecord);
            let recordIndex = searchRecord.findIndex((item: any) => {
                return item.columnKey === tempRecord.columnKey;
            });
            if (recordIndex !== -1) {
                if (tempRecord.value != "") {
                    let _record = [...searchRecord];
                    _record[recordIndex] = tempRecord;
                    setSearchRecord(_record);
                } else {
                    let _record = [...searchRecord];
                    _record.splice(recordIndex, 1);
                    setSearchRecord(_record);
                }
            } else {
                if (tempRecord.value != "") {
                    let _record = [...searchRecord];
                    _record.push(tempRecord);
                    setSearchRecord(_record);
                }
            }
        }
    }, [tempRecord]);

    useEffect(() => {
        // console.log("searchRecord activated: ", searchRecord);
        if (searchRecord?.length > 0) {
            //console.log("Search record testing", searchRecord);
            let data: any = [...dataTable];
            // if (tabKey == "employee") {
            //     data = [...dataTable];
            // }

            let filteredData = data
                .map((element: any) => {
                    let bool = searchRecord?.every((item: any) => {
                        if (item.columnKey.includes("date")) {
                            const dateObject = new Date(
                                element[item.columnKey]
                            );
                            // console.log("im here", dateObject);
                            const day = String(dateObject.getDate()).padStart(
                                2,
                                "0"
                            ); // Get day with leading zero
                            const month = String(
                                dateObject.getMonth() + 1
                            ).padStart(2, "0"); // Get month with leading zero
                            const year = String(dateObject.getFullYear()); // Get full year

                            // Check if any part of the date contains "04"
                            return (
                                day.includes(item.value) ||
                                month.includes(item.value) ||
                                year.includes(item.value) ||
                                moment(dateObject)
                                    .format("DD MMM YYYY")
                                    .toLowerCase()
                                    .includes(item.value.toLowerCase())
                            );
                        } else {
                            return element[item.columnKey]
                                ?.toString()
                                ?.toLowerCase()
                                .includes(item.value.toLowerCase());
                        }
                    });
                    if (bool) return element;
                })
                .filter(
                    (element: any) => element !== null && element !== undefined
                );
            setFilteredDataTable(filteredData);
        } else {
            setFilteredDataTable(null);
        }
    }, [searchRecord, refreshData]);

    const handleSearch = (record: any) => {
        setTempRecord(record);
    };

    const handleTabChange = async (key: any) => {
        try {
            // await context.handlePageChange();
            setTableColumns([]);
            setDataTable([]);
            setSideContainerOpen(false);
            setSubTabKey(key);
            setExpandRowKey([]);
            setActiveRowKey(null);
            setActiveGroupKey(null);
        } catch {}
    };

    const calculateTableHeight = () => {
        const table: any = document.getElementById("table-container");
        if (table) {
            setTableHeight(table?.clientHeight - 65 - 64);
        }
    };

    const setSideContainer = () => {
        if (sideContainerOpen) {
            return (
                sideContainerOpen && (
                    <AdministrationCustomForm
                        setTabKey={setTabKey}
                        setSideContainerOpen={setSideContainerOpen}
                        activeRowKey={activeRowKey}
                        setActiveRowKey={setActiveRowKey}
                        sideContainerOpen={sideContainerOpen}
                        data={activeRecord}
                        tabKey={tabKey}
                        subTabKey={subTabKey}
                        employeeData={dbData.employee_id}
                        holidayData={holidayData}
                        workdayData={workdayData}
                        refreshData={refreshData}
                        setRefreshData={setRefreshData}
                    />
                )
            );
        }
    };

    useEffect(() => {
        Promise.all([getLookupItem("employee")]).then((data: any) => {
            const object: any = {};
            object["employee_id"] = data[0];
            setDbData(object);
            // console.log("DbData: ", object);

            // const test = object["employee_id"].find((item: any) => item.staff_id_num == "E001");
            // console.log("test --------------------", test);
        });

        getLookupItem("workday").then((workday_data: any) => {
            getLookupItem("holiday").then((holiday_data: any) => {
                setHolidayData(holiday_data);
                setWorkdayData(workday_data);
            });
        });

        getLookupItem("policy").then((policy_data: any) => {
            setPolicyData(policy_data);
        });
    }, []);

    const handleLeaveApplication = () => {
        const formList = getFormOptionList(tabKey, "configuration");

        // const handleFinish = () => {
        //     setShowLeaveApplication(false);
        //     leaveform.resetFields();
        //     setImgNameUUIDList([]);
        //     setRefreshData(!refreshData);
        // };

        return (
            <div></div>
            // <LeaveApplication
            //     role={role}
            //     userData={userData}
            //     // activeRowKey={activeRowKey}
            //     showLeaveApplication={showLeaveApplication}
            //     setShowLeaveApplication={setShowLeaveApplication}
            //     formList={formList}
            //     leaveform={leaveform}
            //     imgNameUUIDList={imgNameUUIDList}
            //     setImgNameUUIDList={setImgNameUUIDList}
            //     workdayData={workdayData}
            //     holidayData={holidayData}handlePageChange
            //     dbData={dbData["employee_id"]}
            //     refreshData={refreshData}
            //     setRefreshData={setRefreshData}
            //     // setInputType={setInputType}
            //     // handleFinish={handleFinish}
            //     // handleCancel={handleCancel}
            //     // handleReset={handleReset}
            // />
        );
    };

    useEffect(() => {
        // console.log(holidayData, workdayData);
        // console.log("1-tabKey", tabKey);
        setReady(false);
        window.addEventListener("resize", () => {
            calculateTableHeight();
        });
        Emitter.emit("loading", null);
        let columns: any = getColumnList(tabKey, handleSearch, subTabKey);
        setAddButtonProp(getAddButtonProp(tabKey));

        const itemKey = subTabKey === "roles" ? "employee" : subTabKey;
        const sortKey =
            subTabKey === "position"
                ? "position_name"
                : subTabKey === "leavetype"
                ? "leave_type_name"
                : subTabKey === "holiday"
                ? "ph_date"
                : subTabKey === "policy"
                ? "policy_name"
                : "";
        // console.log("test", itemKey);
        getLookupItem(itemKey)
            .then((_data: any) => {
                // console.log("test data,", tabKey);
                let data: any = [];
                let key = columns[0].dataIndex;
                // console.log("test 1", tabKey);
                if (tabKey == "administration") {
                    data = _data;
                    //   if (subTabKey == "position") {
                    //       if (positionData) {
                    //           data = positionData;
                    //       }
                    //   }
                    //   } else if (subTabKey == "intern") {
                    //       data = _data?.filter(
                    //           (item: any) =>
                    //               item.position === "Intern" &&
                    //               (item.status === "active" ||
                    //                   item.status === undefined)
                    //       );
                    //   } else if (subTabKey == "archived") {
                    //       data = _data?.filter(
                    //           (item: any) => item.status === "inactive"
                    //       );
                    //   }
                    //     } else if (tabKey == "leaveManager") {
                    //         if (subTabKey == "all_leave") {
                    //             data = _data;
                    //         } else if (subTabKey == "pending") {
                    //             data = _data.filter(
                    //                 (item: any) => item.status == "pending"
                    //             );
                    //         } else if (subTabKey == "approved") {
                    //             data = _data.filter(
                    //                 (item: any) => item.status == "approved"
                    //             );
                    //         } else if (subTabKey == "rejected") {
                    //             data = _data.filter(
                    //                 (item: any) => item.status == "rejected"
                    //             );
                    //         } else if (subTabKey == "cancelled") {
                    //             data = _data.filter(
                    //                 (item: any) => item.status == "cancelled"
                    //             );
                    //         } else {
                    //             data = _data;
                    //         }
                } else {
                    data = _data;
                }
                if (data) {
                    if (sortKey != "") {
                        data = data
                            .map((element: any) => {
                                return {
                                    ...element,
                                };
                            })
                            .sort((a: any, b: any) =>
                                a[sortKey].localeCompare(b[sortKey])
                            );
                    }

                    //   data = tabKey === "administration" ? data.reverse() : data;
                    //console.log(data);
                    //   if (tabKey === "administration") {
                    //       data = data.map((element: any) => {
                    //           const duration_in_days: any = daysCalculation(
                    //               workdayData,
                    //               holidayData,
                    //               element.start_date,
                    //               element.end_date,
                    //               element.half_days
                    //           );
                    //           const duration =
                    //               duration_in_days <= 1
                    //                   ? `${duration_in_days} day`
                    //                   : `${duration_in_days} days`;
                    //           return { ...element, duration: duration };
                    //       });
                    //   }

                    // console.log("1-data11", data);
                    let renderedColumns = [];

                    if (columns) {
                        renderedColumns = columns.map((element: any) => {
                            if (element.isActive == true) {
                                element.render = (text: any, record: any) => {
                                    const id =
                                        record._id ||
                                        record.uuid ||
                                        record.group_uuid;
                                    const employee_name = record.fullname_nric;
                                    if (text) {
                                        return (
                                            <a
                                                onClick={() => {
                                                    if (
                                                        tabKey ==
                                                        "administration"
                                                    ) {
                                                        setSideContainerOpen(
                                                            true
                                                        );
                                                        setActiveRowKey(id);
                                                        setActiveRecord(record);
                                                        setHolidayData(
                                                            holidayData
                                                        );
                                                        setWorkdayData(
                                                            workdayData
                                                        );
                                                    } else if (
                                                        tabKey == "leaveManager"
                                                    ) {
                                                        // console.log(
                                                        //     "showModal active"
                                                        // );
                                                        setSideContainerOpen(
                                                            true
                                                        );
                                                        setActiveRowKey(id);
                                                        setActiveRecord(record);
                                                        setHolidayData(
                                                            holidayData
                                                        );
                                                        setWorkdayData(
                                                            workdayData
                                                        );
                                                    }
                                                }}
                                                className="table-clickable-text"
                                            >
                                                {text}
                                            </a>
                                        );
                                    }
                                };
                            }

                            return element;
                        });
                        setTableColumns(renderedColumns);
                        setDataTable(data);
                    }
                } else {
                    setTableColumns([]);
                    setDataTable([]);
                }
            })
            .catch((e: any) => {
                //console.log(e);
                setTableColumns([]);
                setDataTable([]);
            })
            .finally(() => {
                setReady(true);
                calculateTableHeight();
                setFilteredDataTable(null);
                setSearchRecord([]);
                setTempRecord(null);
                Emitter.emit("finish-loading", null);
            });
    }, [tabKey, subTabKey, refreshData, holidayData, workdayData]);

    const getSubTabKeys = () => {
        if (tabKey == "administration") {
            return [
                {
                    label: "Position",
                    key: "position",
                },
                {
                    label: "Leave Type",
                    key: "leavetype",
                },
                {
                    label: "Holiday",
                    key: "holiday",
                },
                {
                    label: "Workday",
                    key: "workday",
                },
                {
                    label: "Policy",
                    key: "policy",
                },
            ];
        } else {
            return [];
        }
    };

    return (
        <div
            style={{
                flex: "1",
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                maxHeight: "100%",
                overflow: "hidden",
            }}
        >
            <div
                className="main-container"
                style={{
                    width: "100%",
                    maxHeight: "100%",
                    maxWidth: isMobile ? "70%" : "70%",
                    overflow: "hidden",
                    display: sideContainerOpen && isMobile ? "none" : "flex",
                    flexDirection: "column",
                    borderRadius: "3px",
                }}
            >
                <div className="generic-header" style={{ height: "50px" }}>
                    {/* Configurations */}
                    {/* {tabKey.charAt(0).toUpperCase() + tabKey.slice(1)} */}
                    {containerTitle}
                </div>
                <div
                    className="generic-content"
                    style={{
                        flex: "1",
                        gap: "0px",
                        maxHeight: "100%",
                        overflow: "hidden",
                        flexWrap: "nowrap",
                        flexDirection: isMobile ? "column" : "row",
                    }}
                >
                    <Tabs
                        style={{ width: isMobile ? "100%" : "auto" }}
                        tabPosition={isMobile ? "top" : "left"}
                        size={isMobile ? "small" : "large"}
                        onChange={handleTabChange}
                        activeKey={subTabKey}
                        items={getSubTabKeys()}
                    ></Tabs>
                    <div
                        style={{
                            flex: "1",
                            height: "100%",
                            display: "flex",
                            maxWidth: "100%",
                            overflow: "hidden",
                            flexDirection: "column",
                        }}
                    >
                        {" "}
                        <div
                            className="generic-table-header"
                            style={{ display: "flex", flexDirection: "row" }}
                        >
                            <span
                                style={{
                                    marginRight: "auto",
                                    minHeight: "32px",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {tabKey === "administration" &&
                                    (subTabKey === "leavetype"
                                        ? "Leave type"
                                        : subTabKey.charAt(0).toUpperCase() +
                                          subTabKey
                                              .split("_")
                                              .join(" ")
                                              .slice(1))}{" "}
                                directory
                            </span>

                            {/* {addButtonProp ? (
								<Dropdown
									disabled={sideContainerOpen}
									menu={{
										items: addButtonProp,
										onClick: (ev: any) => {
											navigate("configuration", {
												replace: false,
												state: { tabKey },
											});
											// resetForm();
											// setFormOption(ev.key);
											// setActiveRowKey(false);
											// setSideContainerOpen(true);
										},
									}}
									placement="bottomLeft"
								>
									<Button>+ Add</Button>
								</Dropdown>
							) : (
								<Button
									onClick={() => {
										navigate("configuration", {
											replace: false,
											state: { tabKey, name: "Add new employee" },
										});
									}}
									// style={tabKey == "country" ? { display: "none" } : {}}
									disabled={sideContainerOpen}
								>
									+ Add
								</Button>
							)} */}
                            {tabKey == "administration" &&
                                (subTabKey === "position" ||
                                    subTabKey === "leavetype" ||
                                    subTabKey === "holiday" ||
                                    subTabKey === "policy") && (
                                    <Button
                                        onClick={() => {
                                            setSideContainerOpen(true);
                                            setActiveRecord(null);
                                            setHolidayData(holidayData);
                                            setWorkdayData(workdayData);
                                        }}
                                        // style={tabKey == "country" ? { display: "none" } : {}}
                                        disabled={sideContainerOpen}
                                    >
                                        + Add
                                    </Button>
                                )}
                            {tabKey == "leaveManager" &&
                                subTabKey == "all_leave" && (
                                    <Button
                                        onClick={() => {
                                            switch (tabKey) {
                                                case "employee":
                                                    navigate("configuration", {
                                                        replace: false,
                                                        state: {
                                                            tabKey,
                                                            subTabKey,
                                                            name: `Add new ${subTabKey}`,
                                                        },
                                                    });
                                                    break;
                                                case "leaveManager":
                                                    setShowLeaveApplication(
                                                        true
                                                    );
                                                    // handleLeaveApplication();
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }}
                                        // style={tabKey == "country" ? { display: "none" } : {}}
                                        // disabled={sideContainerOpen}
                                    >
                                        + Add
                                    </Button>
                                )}
                        </div>
                        <div
                            id="table-container"
                            style={{
                                flex: 1,
                                maxHeight: "100%",
                                overflow: "hidden",
                            }}
                        >
                            {ready && (
                                <Table
                                    rowClassName={(record: any) =>
                                        record?.uuid === activeRowKey ||
                                        `${record.group_uuid}-${record.tag_uuid}` ===
                                            activeGroupKey ||
                                        (record.group_uuid === activeRowKey &&
                                            !record.uuid)
                                            ? "antd-table-row-active"
                                            : ""
                                    }
                                    pagination={{
                                        defaultPageSize: 20,
                                        pageSizeOptions: [20, 50, 100],
                                        showSizeChanger: true,
                                        position: ["bottomCenter"],
                                    }}
                                    columns={tableColumns}
                                    expandable={{
                                        expandedRowKeys: expandedRowKeys,
                                        onExpand: (
                                            expand: any,
                                            record: any
                                        ) => {
                                            let keys = [...expandedRowKeys];
                                            if (expand) {
                                                keys.push(record.uuid);
                                            } else {
                                                let index = keys.findIndex(
                                                    (item: any) =>
                                                        item == record.uuid
                                                );
                                                if (index != -1) {
                                                    keys.splice(index, 1);
                                                }
                                            }
                                            setExpandRowKey(keys);
                                        },
                                    }}
                                    scroll={
                                        tableHeight && {
                                            y: tableHeight,
                                            x: "0px",
                                        }
                                    }
                                    dataSource={filteredDataTable || dataTable}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {setSideContainer()}
            {/* {RejectionModal()} */}

            {handleLeaveApplication()}
            {/* {LeaveConfiguration()} */}
            {/* <LeaveApplication /> */}
        </div>
        // </div>
    );
};

// LookupManager.propTypes = {
// 	params: PropTypes.any,
// };

export default LookupConfiguration;
// function setCurrent(arg0: () => any) {
//     throw new Error("Function not implemented.");
// }
