import axios from "axios";
import { AuthHeader, serverConnection } from "../../utils/_exports";
const apiURL = "public_holiday";

// leave GET route
export const getHolidayList = () => {
    return new Promise((resolve, reject) => {
        axios
            // .get(`${url}/${apiURL}/`, {
            .get(`${serverConnection}/${apiURL}/`, {
                // .get(`http://localhost:5000/api/${apiURL}/`, {
                ...AuthHeader,
            })
            .then((_data: any) => {
                if (_data?.data.data.data && _data.data.data.data.length) {
                    resolve(_data.data.data.data);
                } else {
                    resolve(null);
                }
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

// leave GET route by public holiday id
export const getHolidayListById = (public_holiday_id: string) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${serverConnection}/${apiURL}/`, {
                params: { public_holiday_id },
                ...AuthHeader,
            })
            .then((_data: any) => {
                if (_data?.data.data.data && _data.data.data.data.length) {
                    resolve(_data.data.data.data);
                } else {
                    resolve(null);
                }
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

//leave POST route
export const addHolidayItem = (data: any) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${serverConnection}/${apiURL}/`, data, AuthHeader)
            .then((_data: any) => {
                resolve(_data.data.data.data);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

//leave PUT route
export const updateHolidayItem = (_ids: Array<any>, data: any) => {
    const _data = {
        query: { _id: _ids[0] },
        data: data,
    };
    return new Promise((resolve, reject) => {
        axios
            // .put(`${url}/${apiURL}/`, _data, AuthHeader)
            .put(`${serverConnection}/${apiURL}/`, _data, AuthHeader)
            .then((_data: any) => {
                resolve(_data.data.data.data);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

//leave DELETE route
export const deleteHolidayItem = (_id: any) => {
    return new Promise((resolve, reject) => {
        axios
            // .delete(`${url}/${apiURL}/${_id}`, {
            .delete(`${serverConnection}/${apiURL}/${_id}`, {
                params: {
                    _id: _id,
                },
                ...AuthHeader,
            })
            .then((_data: any) => {
                resolve(_data.data.data.data);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};
