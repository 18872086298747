import React, { useContext, useEffect, useMemo, useState } from "react";
import { Space, Tabs, Table, Button, Typography } from "antd";

import { HomeContext } from "./Home";
import { getLeaveList } from "../services/api-server/leave";
import { getLookupItem } from "../utils/lookup_list";
// import { Document, Page } from "react-pdf";

// import { PDFViewer } from "@react-pdf/renderer";
import { pdfjs } from "react-pdf";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { isMobile } from "react-device-detect";
import { fetchFile } from "../services/api-server/filetransfer";
import moment from "moment";
// import pdfjs from "pdfjs-dist";

let WORKER_URL = "";

if (!pdfjs.GlobalWorkerOptions.workerSrc) {
    WORKER_URL = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    pdfjs.GlobalWorkerOptions.workerSrc = WORKER_URL;
}

const { Text, Link, Title } = Typography;

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     "pdfjs-dist/build/pdf.worker.min.mjs",
//     import.meta.url
// ).toString();

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

function PoliciesContainer(props: any) {
    const context: any = useContext(HomeContext);
    const [tabKey, setTabKey] = useState<any>("");
    const [subTabKey, setSubTabKey] = useState<any>("");
    const [tabKeys, setTabKeys] = useState([]);
    const [sideContainerOpen, setSideContainerOpen] = useState<boolean>(false);
    const [activeRowKey, setActiveRowKey] = useState<any>(null);
    const [activeRecord, setActiveRecord] = useState<any>(null);
    const [holidayData, setHolidayData] = useState<any>([]);
    const [workdayData, setWorkdayData] = useState<any>([]);
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [policyList, setPolicyList] = useState([]);
    const [pdfURL, setPdfURL] = useState<any>(null);
    const [last_updated, setLast_updated] = useState<any>(null);

    const role = props.params.userRole[0];
    const userData = props.params.user;

    useEffect(() => {
        getLookupItem("workday").then((workday_data: any) => {
            getLookupItem("holiday").then((holiday_data: any) => {
                setHolidayData(holiday_data);
                setWorkdayData(workday_data);
            });
        });

        getLookupItem("policy").then((policyData: any) => {
            if (policyData) {
                setPolicyList(policyData);
                policyData = policyData?.sort((a: any, b: any) =>
                    a["policy_name"].localeCompare(b["policy_name"])
                );
                setTabKeys(
                    policyData?.map((data: any) => {
                        return {
                            label: data?.policy_name,
                            key: data?.policy_name,
                        };
                    })
                );
                setTabKey(policyData[0].policy_name);
                setLast_updated(
                    moment(new Date(policyData[0].last_updated)).format(
                        "DD MMM YYYY"
                    )
                );
                fetchFile(
                    policyData[0].policy_name,
                    policyData[0].uuid,
                    "policy_files"
                ).then((url: any) => {
                    setPdfURL(url);
                });
            }
        });
    }, []);

    useEffect(() => {
        if (tabKey && policyList) {
            const data: any = policyList?.find(
                (item: any) => item.policy_name === tabKey
            );

            if (data?.filename && data?.uuid) {
                fetchFile(data.filename, data.uuid, "policy_files").then(
                    (url: any) => {
                        window.URL.revokeObjectURL(pdfURL);
                        setPdfURL(url);
                    }
                );
                setLast_updated(
                    moment(new Date(data.last_updated)).format("DD MMM YYYY")
                );
            }
        }
    }, [tabKey]);

    const changeContent = () => {
        function onDocumentLoadSuccess({
            numPages,
        }: {
            numPages: number;
        }): void {
            setNumPages(numPages);
        }
        const newplugin: any = defaultLayoutPlugin();
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    height: "83%",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                        height: "100%",
                        width: "100%",
                    }}
                >
                    {pdfURL && (
                        <Worker workerUrl={WORKER_URL}>
                            <Viewer
                                fileUrl={pdfURL}
                                plugins={[newplugin]}
                            ></Viewer>
                        </Worker>
                    )}
                    <div
                        style={{
                            color: "black",
                            fontSize: "20px",
                            textAlign: "center",
                            padding: "15%",
                            display: !pdfURL ? "" : "none",
                        }}
                    >
                        {!pdfURL && (
                            <Title level={2}>
                                Policies have not been uploaded
                            </Title>
                        )}
                    </div>
                </div>
                <div
                    style={{
                        width: isMobile ? "100%" : "30%",
                        paddingLeft: isMobile ? "" : "20px",
                    }}
                >
                    {last_updated && (
                        <Text
                            italic
                            style={{ fontSize: isMobile ? "" : "20px" }}
                        >
                            Policy last updated on: {last_updated}
                        </Text>
                    )}
                </div>
            </div>
        );
    };

    const handleTabChange = async (key: any) => {
        try {
            await context.handlePageChange();
            setTabKey(key);
        } catch {}
    };

    return (
        <>
            <div className="generic-container" style={{ gap: "20px" }}>
                <Space direction="vertical">
                    <span
                        style={{
                            fontSize: "20px",
                            fontWeight: "600",
                            color: "rgba(0, 0, 0, 0.85)",
                        }}
                    >
                        {props.params.propTitle}
                    </span>
                </Space>

                <Tabs
                    onChange={handleTabChange}
                    activeKey={tabKey}
                    items={tabKeys}
                ></Tabs>

                {changeContent()}
            </div>
        </>
    );
}

export default PoliciesContainer;
