import React from "react";
import type { FormInstance } from "antd";
import { Button, Form, Input, Space } from "antd";

const FormButtonCancel = ({
    form,
    handleFormCancel,
    formReset,
}: {
    form: FormInstance;
    handleFormCancel: any;
    formReset: any | null;
}) => {
    const [submittable, setSubmittable] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState<any>(null);

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
        if (form.isFieldsTouched() && initialValues && formReset == false) {
            const valuesChanged = Object.keys(values).some((key) => {
                if (typeof values[key] == "object") {
                    return (
                        JSON.stringify(values[key]) !==
                        JSON.stringify(initialValues[key])
                    );
                }
                else if (typeof initialValues[key] === "number" && typeof values[key] !== "number") {
                    return values[key] !== JSON.stringify(initialValues[key]);
                } else {
                    return values[key] !== initialValues[key];
                }
            });

            if (valuesChanged) {
                setSubmittable(true);
            } else {
                setSubmittable(false);
            }
        } else {
            setSubmittable(false);
        }
    }, [values, initialValues]);

    React.useEffect(() => {
        if (formReset) {
            if (values) {
                setInitialValues(values);
            }
        }
    }, [values]);

    return (
        <Button
            onClick={() => handleFormCancel()}
            className="ant-btn-secondary"
            disabled={!submittable}
        >
            Cancel
        </Button>
    );
};

export default FormButtonCancel;
