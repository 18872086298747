import axios from "axios";
import { AuthHeader, url } from "../../utils/_exports";
const apiURL = "filetransfer";

// Get file
export const fetchFile = (filename: any, uuid: any, folder_name: any) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${url}/${apiURL}/download?filename=${filename}&uuid=${uuid}&folder_name=${folder_name}`,
                {
                    ...AuthHeader,
                    responseType: "blob",
                }
            )
            .then((response) => {
                const url = window.URL.createObjectURL(response.data);
                resolve(url);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

// Download file
export const downloadFile = (filename: any, uuid: any, folder_name: any) => {
    return new Promise<void>((resolve, reject) => {
        axios
            .get(
                `${url}/${apiURL}/download?filename=${filename}&uuid=${uuid}&folder_name=${folder_name}`,
                {
                    ...AuthHeader,
                    responseType: "blob",
                }
            )
            .then((response) => {
                const url = window.URL.createObjectURL(response.data);
                const a = document.createElement("a");
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                resolve();
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

// Upload file
export const uploadFile = (formData: any) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${url}/${apiURL}/`, formData, AuthHeader)
            .then((_data: any) => {
                //console.log(_data);
                resolve(_data);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

// Delete file
export const deleteFile = (uuid: any, folder_name: any) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(
                `${url}/${apiURL}?uuid=${uuid}&folder_name=${folder_name}`,
                {
                    ...AuthHeader,
                }
            )
            .then((_data: any) => {
                resolve(_data);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};
