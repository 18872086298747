import axios from "axios";
import { AuthHeader, serverConnection } from "../../utils/_exports";
const apiURL = "leave_type";

// leave_type GET route
export const getLeaveTypeList = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${serverConnection}/${apiURL}/`, { ...AuthHeader })
            .then((_data: any) => {
                //console.log(_data);
                if (_data?.data.data.data && _data.data.data.data.length) {
                    resolve(_data.data.data.data);
                } else {
                    resolve(null);
                }
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

// leave_type GET route by leave type id
export const getLeaveTypeListById = (leave_type_id: string) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${serverConnection}/${apiURL}/`, {
                params: { leave_type_id },
                ...AuthHeader,
            })
            .then((_data: any) => {
                if (_data?.data.data.data && _data.data.data.data.length) {
                    resolve(_data.data.data.data);
                } else {
                    resolve(null);
                }
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

//leave_type POST route
export const addLeaveTypeItem = (data: any) => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${serverConnection}/${apiURL}/`, data, AuthHeader)
            .then((_data: any) => {
                resolve(_data.data.data.data);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

//leave_type PUT route
export const updateLeaveTypeItem = (_ids: Array<any>, data: any) => {
    const _data = {
        query: { _id: _ids[0] },
        data: data,
    };
    return new Promise((resolve, reject) => {
        axios
            .put(`${serverConnection}/${apiURL}/`, _data, AuthHeader)
            .then((_data: any) => {
                resolve(_data.data.data.data);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

//leave_type DELETE route
export const deleteLeaveTypeItem = (_id: any) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${serverConnection}/${apiURL}/${_id}`, {
                data: { _id: _id },
                ...AuthHeader,
            })
            .then((_data: any) => {
                resolve(_data.data.data.data);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};
