import axios from "axios";
import { AuthHeader, serverConnection } from "../../utils/_exports";
const apiURL = "leave";
// const serverConnection =
//     `${url}`;                            // Live IP
// `http://192.168.188.227:5000/api`;   // LAN IP
// `http://10.188.188.227:5000/api`;    // Remote IP
// `http://localhost:5000/api`;         // Localhost

// leave GET route
export const getLeaveList = () => {
    return new Promise((resolve, reject) => {
        // console.log("k------------------",  url, "!---- end")
        axios
            .get(
                // `${url}/${apiURL}/`,
                // `http://192.168.188.227:5000/api/${apiURL}/`,
                `${serverConnection}/${apiURL}/`,
                {
                    ...AuthHeader,
                }
            )
            .then((_data: any) => {
                if (_data?.data.data.data && _data.data.data.data.length) {
                    resolve(_data.data.data.data);
                } else {
                    resolve(null);
                }
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

// leave GET route by leave id
export const getLeaveListById = (leave_id: string) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                // `${url}/${apiURL}/`,
                // `http://
                `${serverConnection}/${apiURL}/`,
                {
                    params: { leave_id },
                    ...AuthHeader,
                }
            )
            .then((_data: any) => {
                if (_data?.data.data.data && _data.data.data.data.length) {
                    resolve(_data.data.data.data);
                } else {
                    resolve(null);
                }
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};

//leave POST route
export const addLeaveItem = (data: any) => {
    // auto increment id by getting the leave id num with getLeaveList
    const getLeaveIdNum = async () => {
        try {
            const res: any = await getLeaveList();
            // get only the leave_id_num from the list
            let nextNumericValue;
            if (res) {
                const leaveIds = res.map((item: any) => item.leave_id_num);

                // Extract numeric parts and find the maximum value
                const numericValues = leaveIds.map((id: string) =>
                    parseInt(id.substring(1))
                );
                const maxNumericValue = Math.max(...numericValues);

                // Increment the maximum value
                nextNumericValue = maxNumericValue + 1;
            } else {
                nextNumericValue = 1;
            }

            // Format the incremented value to match the desired format ('L' followed by padded numbers)
            const nextId = "L" + nextNumericValue.toString().padStart(5, "0"); // Assuming 5 digits after 'L'

            return nextId;
        } catch (error) {
            console.error("Error fetching leave IDs:", error);
            throw error; // Re-throw the error to handle it elsewhere if needed
        }
    };

    return new Promise((resolve, reject) => {
        // Call getLeaveIdNum using await to get the next leave ID
        getLeaveIdNum()
            .then((nextLeaveId: string) => {
                //console.log("new leave id: ", nextLeaveId);
                data.leave_id_num = nextLeaveId;
                // data.append("leave_id_num", nextLeaveId);
                //console.log("Form values", data);

                axios
                    .post(
                        // `${url}/${apiURL}/`,
                        // `http://192.168.188.227:5000/api/${apiURL}/`,
                        // `${serverConnection}/${apiURL}/`,
                        `${serverConnection}/${apiURL}/applyleave/`,
                        data,
                        AuthHeader
                    )
                    .then((_data: any) => {
                        resolve(_data.data.data.data);
                    })
                    .catch((e: any) => {
                        reject(e);
                    });
            })
            .catch((error: any) => {
                reject(error);
            });
    });
};

//leave PUT route
export const updateLeaveItem = (_ids: Array<any>, data: any) => {
    const _data = {
        // query: { _id: { $in: _ids } },
        query: { _id: _ids[0] },
        data: data,
    };
    //console.log("TESTTTT", _data);
    return new Promise((resolve, reject) => {
        axios
            .put(
                // `${url}/${apiURL}/`,
                // `http://192.168.188.227:5000/api/${apiURL}/`,
                `${serverConnection}/${apiURL}/`,
                _data,
                AuthHeader
            )
            .then((_data: any) => {
                //console.log(_data);
                resolve(_data.data.data.data);
            })
            .catch((e: any) => {
                //console.log(e);
                reject(e);
            })
            .finally(() => {
                //console.log("leave data updated", _data);
            });
    });
};

//leave DELETE route
export const deleteLeaveItem = (_id: any) => {
    return new Promise((resolve, reject) => {
        axios
            .delete(
                // `${url}/${apiURL}/${_id}`,
                // `http://192.168.188.227:5000/api/${apiURL}/${_id}`,
                `${serverConnection}/${apiURL}/${_id}`,
                {
                    params: { _id },
                    ...AuthHeader,
                }
            )
            .then((_data: any) => {
                resolve(_data.data.data.data);
            })
            .catch((e: any) => {
                reject(e);
            });
    });
};
